import React from 'react';
import {
  CellularAPNConfig,
  DeviceConfig,
  DeviceType,
  IPTableAction,
} from '@edgeiq/edgeiq-api-js';
import { Typography, Grid, Paper, Box } from '@mui/material';

import { useAppDispatch } from '../../../redux/hooks';
import { setNewDeviceConfigConnection } from '../../../redux/reducers/configs.reducer';
import DeviceConfigForm from '../../../containers/Forms/DeviceConfigForm';
import DeviceConfigConnectionForm from '../../../containers/Forms/DeviceConfigForm/DeviceConfigConnectionForm';
import {
  addNewDeviceIPTable,
  removeNewDeviceConfigIPTable,
} from '../../../containers/Forms/DeviceConfigForm/helper';

interface ConfigDetailsProps {
  newDeviceConfigConnection: DeviceConfig | null;
  deviceTypes: DeviceType[];
}

const ConfigDetails: React.FC<ConfigDetailsProps> = ({
  newDeviceConfigConnection,
  deviceTypes,
}) => {
  const dispatch = useAppDispatch();

  const deviceType = deviceTypes.find((devType) => {
    return devType._id === newDeviceConfigConnection?.device_type_id;
  });

  const handleChange = (prop: string, value: string | number): void => {
    dispatch(
      setNewDeviceConfigConnection({
        ...newDeviceConfigConnection,
        [prop]: value,
      } as DeviceConfig),
    );
  };

  const handleDynamicChange = (
    prop: string,
    value: string | number | CellularAPNConfig[],
    field: string,
    index: number,
  ): void => {
    switch (field) {
      case 'iptables':
        const ipTables = newDeviceConfigConnection?.iptables || [];

        ipTables[index] = {
          ...ipTables[index],
          [prop]: value,
        } as IPTableAction;
        dispatch(
          setNewDeviceConfigConnection({
            ...newDeviceConfigConnection,
            iptables: ipTables,
          } as DeviceConfig),
        );
        break;
      default:
        const tempDeviceConecctions =
          newDeviceConfigConnection?.connections || [];

        tempDeviceConecctions[index] = {
          config: {
            ...tempDeviceConecctions[index].config,
            [prop]: value,
          },
          name: tempDeviceConecctions[index]?.name,
          type: tempDeviceConecctions[index]?.type,
        };
        dispatch(
          setNewDeviceConfigConnection({
            ...newDeviceConfigConnection,
            connections: tempDeviceConecctions,
          } as DeviceConfig),
        );
        break;
    }
  };

  const onAddNewIPTable = (): void => {
    return dispatch(
      setNewDeviceConfigConnection(
        addNewDeviceIPTable(newDeviceConfigConnection as DeviceConfig),
      ),
    );
  };

  const onRemoveNewIPTable = (index: number): void => {
    return dispatch(
      setNewDeviceConfigConnection(
        removeNewDeviceConfigIPTable(
          newDeviceConfigConnection as DeviceConfig,
          index,
        ),
      ),
    );
  };

  return (
    <Grid container spacing={3}>
      {newDeviceConfigConnection && (
        <>
          <Grid item xs={12} className="m-8">
            <Paper className="p-8 shadow">
              <Typography variant="h5">Details</Typography>
              <Box>
                <Grid container direction="row" spacing={2}>
                  <DeviceConfigForm
                    onInputChange={handleChange}
                    deviceTypes={deviceTypes}
                    disabledDeviceType={true}
                    deviceConfig={newDeviceConfigConnection || undefined}
                  />
                  <Grid item xs={12} md={12} className="mt-6">
                    <Typography>Connections</Typography>
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      className="pl-9 pr-6 mt-4"
                    >
                      {deviceType && (
                        <DeviceConfigConnectionForm
                          onInputChange={handleDynamicChange}
                          onAddNewIPTable={onAddNewIPTable}
                          onRemoveIPTableValue={onRemoveNewIPTable}
                          iptables={newDeviceConfigConnection?.iptables}
                          deviceNetConnections={
                            newDeviceConfigConnection?.connections || []
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ConfigDetails;
