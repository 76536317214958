import React, { useState } from 'react';
import { Grid, MenuItem, IconButton } from '@mui/material';
import { IPTableAction } from '@edgeiq/edgeiq-api-js';
import DeleteIcon from '@mui/icons-material/Delete';

import SelectInput from '../../../../components/SelectInput';
import TextInput from '../../../../components/TextInput';
import {
  EMPTY_IP_TABLE,
  TABLE_ENTRY_ACTIONS,
  TABLE_ENTRY_PROTOCOLS,
  TALBE_ENTRY_TYPES,
} from '../../../../constants/configConnections';
import useStyles from '../styles';

interface DeviceConfigConnectionFormProps {
  deviceConnection?: IPTableAction;
  configIndex: number;
  onInputChange: (
    prop: string,
    value: string | number,
    field: string,
    index: number,
  ) => void;
  onRemoveIPTableValue: (ipTableValueIndex: number) => void;
}

const DeviceConfigConnectionIPTableForm: React.FC<
  DeviceConfigConnectionFormProps
> = ({
  configIndex,
  deviceConnection,
  onInputChange,
  onRemoveIPTableValue,
}) => {
  const [configConnection, setConfigConnection] = useState<IPTableAction>(
    deviceConnection || EMPTY_IP_TABLE,
  );
  const classes = useStyles();
  const handerLocalConfigConnection = (
    prop: string,
    value: string | number | boolean,
  ): void => {
    setConfigConnection((currentConfig) => {
      return (currentConfig = {
        ...currentConfig,
        [prop]: value,
      });
    });
  };

  const handleDynamicChange =
    (prop: string, index: number) =>
    (field: string, value: string | number): void => {
      handerLocalConfigConnection(prop, value as string);
      onInputChange(prop, value, 'iptables', index);
    };

  const handleRemoveIPTableValue = (index: number) => (): void => {
    onRemoveIPTableValue(index as number);
  };

  return (
    <>
      <Grid container direction="row" className="mt-2" spacing={2}>
        <Grid item xs={12} className="d-flex flex-justify-end">
          <IconButton
            onClick={handleRemoveIPTableValue(configIndex)}
            className={classes.errorButton}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            label="Action"
            prop="action"
            required={true}
            value={configConnection?.action}
            onSelectChange={handleDynamicChange('action', configIndex)}
            options={Object.keys(TABLE_ENTRY_ACTIONS).map((key, index) => (
              <MenuItem className="m-4 p-2" key={index} dense value={key}>
                {TABLE_ENTRY_ACTIONS[key]}
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            prop="chain"
            label="Chain"
            placeholder=""
            type="text"
            required={true}
            value={configConnection?.chain}
            onInputChange={handleDynamicChange('chain', configIndex)}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Table"
            prop="table"
            value={configConnection?.table}
            onSelectChange={handleDynamicChange('table', configIndex)}
            options={Object.keys(TALBE_ENTRY_TYPES).map((key, index) => (
              <MenuItem className="m-4 p-2" key={index} dense value={key}>
                {TALBE_ENTRY_TYPES[key]}
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Protocol"
            prop="protocol"
            value={configConnection?.protocol}
            onSelectChange={handleDynamicChange('protocol', configIndex)}
            options={Object.keys(TABLE_ENTRY_PROTOCOLS).map((key, index) => (
              <MenuItem className="m-4 p-2" key={index} dense value={key}>
                {TABLE_ENTRY_PROTOCOLS[key]}
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            prop="source"
            label="Source"
            placeholder=""
            type="text"
            value={configConnection?.source}
            onInputChange={handleDynamicChange('source', configIndex)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            prop="destination"
            label="Destination"
            placeholder=""
            type="text"
            value={configConnection?.destination}
            onInputChange={handleDynamicChange('destination', configIndex)}
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            prop="source_port"
            label="Source Port"
            placeholder=""
            type="text"
            value={configConnection?.source_port}
            onInputChange={handleDynamicChange('source_port', configIndex)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            prop="destination_port"
            label="Destination Port"
            placeholder=""
            type="text"
            value={configConnection?.destination_port}
            onInputChange={handleDynamicChange('destination_port', configIndex)}
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            prop="in_interface"
            label="In Interface"
            placeholder=""
            type="text"
            value={configConnection?.in_interface}
            onInputChange={handleDynamicChange('in_interface', configIndex)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            prop="out_interface"
            label="Out Interface"
            placeholder=""
            type="text"
            value={configConnection?.out_interface}
            onInputChange={handleDynamicChange('out_interface', configIndex)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            prop="jump"
            label="Jump"
            placeholder=""
            type="text"
            value={configConnection?.jump}
            onInputChange={handleDynamicChange('jump', configIndex)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DeviceConfigConnectionIPTableForm;
