import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Paper, Typography, MenuItem } from '@mui/material';
import { UserInput, Users, UserType, UserTypes } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { setStateUserTypes } from '../../redux/reducers/userTypes.reducer';
import { errorHighlight, optionsPaginationsFilter } from '../../app/constants';
import Publish from '../../containers/Publish';
import Header from '../../containers/HeaderWithActionButton';
import TextInput from '../../components/TextInput';
import SelectInput from '../../components/SelectInput';
import { ADMIN_USER_TYPE_NAME } from '../users/constants';
import useStyles from './styles';
import PasswordInputWithAlert from '../../containers/PasswordInputWithAlert/PasswordInputWithAlert';
import { validateEmail } from '../../helpers/validateEmail';
import { titleCase } from '../../helpers/utils';

const EmptyUser: UserInput = {
  company_id: '',
  email: '',
  first_name: '',
  last_name: '',
  password: '',
  password_confirmation: '',
  user_type_id: '',
};

const CreateUserPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userTypesState = useAppSelector((state: RootState) => state.userTypes);

  const [newUser, setNewUser] = useState<UserInput>(EmptyUser);
  const [userTypes, setUserTypes] = useState<UserType[]>(
    userTypesState.userTypes,
  );
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [isValidEmail, setIsValidEmail] = useState(true);

  const checkSubmitEnable = (): void => {
    setEnableSubmit(
      newUser.first_name !== '' &&
        newUser.last_name !== '' &&
        newUser.email !== '' &&
        validateEmail(newUser.email) &&
        newUser.password !== '' &&
        newUser.password_confirmation !== '' &&
        newUser.company_id !== '' &&
        newUser.user_type_id !== '',
    );
  };

  useEffect(() => {
    checkSubmitEnable();
  }, [newUser]);

  useEffect(() => {
    if (userTypes.length === 0) {
      UserTypes.list({}, optionsPaginationsFilter)
        .then((result) => {
          const { userTypes: resultUserTypes } = result;
          setUserTypes(resultUserTypes);
          dispatch(setStateUserTypes(resultUserTypes));
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
  }, []);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const handleChange = (
    prop: string,
    value: string | number | string[],
  ): void => {
    setNewUser({ ...newUser, [prop]: value });
    setIsValidEmail(validateEmail(newUser.email));
  };

  const handleUserChange = (
    prop: string,
    value: string | number | string[],
  ): void => {
    setNewUser({ ...newUser, [prop]: value });
  };

  const handleOnAccountChange = (companyId: string): void => {
    handleUserChange('company_id', companyId);
  };

  const handlePublishSubmit = (): void => {
    setSubmitting(true);
    Users.create(newUser)
      .then((_result) => {
        dispatch(
          setAlert({
            highlight: 'User created successfully',
            type: 'success',
          }),
        );
        navigate('/users');
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.messages || error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Grid container direction="row" spacing={3} className="p-9">
      <Grid item xs={12}>
        <Header
          goBack="users"
          goBackLabel="Users"
          isCreatePage={true}
          model="user"
        />
      </Grid>
      <Grid item xs={8}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <Paper className="shadow p-8 ">
              <div className={clsx('mb-6', classes.titleContainer)}>
                <Typography
                  data-cy="create-user-title"
                  variant="h5"
                  className={classes.title}
                >
                  Create a new User
                </Typography>
              </div>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={6} className="mt-6">
                  <TextInput
                    label="First Name"
                    prop="first_name"
                    required={true}
                    value={newUser.first_name}
                    onInputChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} className="mt-6">
                  <TextInput
                    label="Last Name"
                    prop="last_name"
                    required={true}
                    value={newUser.last_name}
                    onInputChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={6} className="mt-6">
                  <TextInput
                    label="Email"
                    prop="email"
                    required={true}
                    value={newUser.email}
                    onInputChange={handleChange}
                    error={!isValidEmail}
                  />
                  {!isValidEmail && (
                    <Typography variant="subtitle2" color="error">
                      Invalid email
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6} className="mt-6">
                  <SelectInput
                    label="Role"
                    placeholder="Choose a role"
                    prop="user_type_id"
                    required={true}
                    value={newUser.user_type_id}
                    onSelectChange={handleChange}
                    options={userTypes
                      .filter((type) => type.name !== ADMIN_USER_TYPE_NAME)
                      .map((userType) => (
                        <MenuItem dense key={userType._id} value={userType._id}>
                          {titleCase(userType.name)}
                        </MenuItem>
                      ))}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={6} className="mt-6">
                  <PasswordInputWithAlert
                    label="Password"
                    prop="password"
                    required={true}
                    value={newUser.password}
                    onInputChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} className="mt-6">
                  <TextInput
                    type="password"
                    label="Repeat Password"
                    prop="password_confirmation"
                    required={true}
                    value={newUser.password_confirmation}
                    onInputChange={handleChange}
                  />
                </Grid>
              </Grid>
              <div className={clsx(classes.formBottom)}></div>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Publish
          label="user"
          submitting={submitting}
          companyId={newUser.company_id}
          onChangeAccount={handleOnAccountChange}
          onSubmit={handlePublishSubmit}
          enableSubmit={enableSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default CreateUserPage;
