import React, { Dispatch } from 'react';
import { Box, Grid, Typography, FormControl } from '@mui/material';
import { Device, DeviceType } from '@edgeiq/edgeiq-api-js';

import DatePicker from '../../../../components/DatePicker';
import DeviceHeartbeat from '../../../../components/DeviceHeartbeat';
import TextInput from '../../../../components/TextInput';
import { GATEWAY_TYPE } from '../../../../app/constants';
import DeviceMqttPassword from '../../../../containers/DeviceMqttPassword';
import ImportantMetadata from '../../deviceMetadata/ImportantMetadata';
import DeviceCertificates from '../deviceCertificates';

interface DeviceFormProps {
  device: Device;
  originalDevice: Device;
  deviceType?: DeviceType | null;
  onInputChange: (prop: string, value: string | number | boolean) => void;
  heartbeatUnit?: string;
  heartbeatPeriod?: number | undefined;
  setHeartbeatPeriod?: Dispatch<React.SetStateAction<number | undefined>>;
  onMetadataChange?: (key: string, value: string | number) => void;
}

const DeviceForm: React.FC<DeviceFormProps> = ({
  onInputChange,
  device,
  originalDevice,
  deviceType,
  heartbeatUnit = 'hours',
  heartbeatPeriod,
  onMetadataChange,
}) => {
  return (
    <Box>
      {/* Row 1 */}
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={6} className="mt-6">
          <TextInput
            label="Device Name"
            value={device.name}
            prop="name"
            onInputChange={onInputChange}
          />
        </Grid>
        <Grid item xs={12} md={6} className="mt-6">
          <TextInput
            label="Location"
            value={device.location}
            prop="location"
            onInputChange={onInputChange}
          />
        </Grid>
      </Grid>

      {/* Row 2 */}
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={6} className="mt-6">
          <TextInput
            label="Serial"
            value={device.serial}
            prop="serial"
            onInputChange={onInputChange}
          />
        </Grid>
        <Grid item xs={12} md={6} className="mt-6">
          <TextInput
            label="Firmware Version"
            value={device.firmware_version}
            prop="firmware_version"
            onInputChange={onInputChange}
          />
        </Grid>
      </Grid>

      {/* Row 3 */}
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={6} className="mt-6">
          <TextInput
            label="Part number"
            value={device.part_number}
            prop="part_number"
            onInputChange={onInputChange}
          />
        </Grid>
      </Grid>

      {/* Row 4 */}
      {/* Important Metadata */}
      <ImportantMetadata
        device={device}
        deviceType={deviceType}
        onInputChange={onMetadataChange}
      />

      {/* Row 5 */}
      {deviceType?.type === GATEWAY_TYPE && (
        <DeviceMqttPassword
          mqttPassword={device.mqtt_password}
          enforceSecureMqtt={device.enforce_secure_mqtt}
          onInputChange={onInputChange}
          hasPasswordDisclaimer={false}
          hasTitle={true}
          passwordChangeInProgress={device.password_change_in_progress}
          authMethod={device.auth_method}
        />
      )}

      {device.device_certificates && device.device_certificates.length && (
        <DeviceCertificates device={device} />
      )}

      {/* Row 5 */}
      <DeviceHeartbeat
        deviceType={deviceType}
        heartbeatUnit={heartbeatUnit}
        heartbeat_period={heartbeatPeriod}
        heartbeat_values={device.heartbeat_values}
        showLabel={true}
        onInputChange={onInputChange}
      />
      {/* Row 6 */}
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={6} className="mt-4">
          <Typography variant="subtitle2" className="custom-label">
            Activated at
          </Typography>
          <FormControl fullWidth>
            <DatePicker
              value={device.activated_at as string}
              prop="activated_at"
              onInputChange={onInputChange}
              disabled={!!originalDevice.activated_at}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} className="mt-4">
          <Typography variant="subtitle2" className="custom-label">
            Deactivated at
          </Typography>
          <FormControl fullWidth>
            <DatePicker
              value={device.deactivated_at as string}
              prop="deactivated_at"
              onInputChange={onInputChange}
              disabled={!!originalDevice.deactivated_at}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeviceForm;
