import {
  DeviceTypeMapping,
  HandlerType,
  Ingestor,
  IngestorInput,
  IngestorRoute,
  IngestorType,
  ListenerFilter,
} from '@edgeiq/edgeiq-api-js';
import { methodsTypes } from '../../../app/constants';
import {
  BACNET_LISTENER,
  DBUS_SIGNAL_HANDLER,
  DBUS_SIGNAL_LISTENER,
  DEFAULT_HANDLER_TYPES_MAP,
  DEV_LISTENER,
  FIXED_HANDLER,
  HTTP_CLIENT_LISTENER,
  ICMP_POLLING_LISTENER,
  INGESTOR_LISTENER_TYPES,
  OPCUA_LISTENER,
  ROUTER_HANDLER,
  SHELL_POLLING_LISTENER,
  SNMP_POLLING_LISTENER,
} from '../../../constants/ingestors';

export const checkListenerTypeDisabled = (
  type: string,
  listenerType: string,
): boolean => {
  if (type === 'edge') {
    return (
      listenerType === INGESTOR_LISTENER_TYPES.cloud ||
      listenerType === INGESTOR_LISTENER_TYPES.cloud_gcp_pubsub_jci ||
      listenerType === INGESTOR_LISTENER_TYPES.cloud_polling_assetlink ||
      listenerType === INGESTOR_LISTENER_TYPES.cloud_polling_inmarsat ||
      listenerType === INGESTOR_LISTENER_TYPES.cloud_polling_orbcomm
    );
  }
  return (
    listenerType === INGESTOR_LISTENER_TYPES.bacnet ||
    listenerType === INGESTOR_LISTENER_TYPES.dbus_signal ||
    listenerType === INGESTOR_LISTENER_TYPES.dev ||
    listenerType === INGESTOR_LISTENER_TYPES.http_client ||
    listenerType === INGESTOR_LISTENER_TYPES.http_server ||
    listenerType === INGESTOR_LISTENER_TYPES.icmp_polling ||
    listenerType === INGESTOR_LISTENER_TYPES.opcua ||
    listenerType === INGESTOR_LISTENER_TYPES.shell_polling ||
    listenerType === INGESTOR_LISTENER_TYPES.snmp_polling ||
    listenerType === INGESTOR_LISTENER_TYPES.tcp_client ||
    listenerType === INGESTOR_LISTENER_TYPES.tcp_modbus ||
    listenerType === INGESTOR_LISTENER_TYPES.tcp_server ||
    listenerType === INGESTOR_LISTENER_TYPES.udp_client ||
    listenerType === INGESTOR_LISTENER_TYPES.udp_server
  );
};

export const checkHandlerTypeDisabled = (
  type: string,
  listenerType: string,
  handlerType: string,
): boolean => {
  switch (handlerType) {
    case 'fixed':
    case 'delimited':
      return (
        type === INGESTOR_LISTENER_TYPES.cloud ||
        listenerType === INGESTOR_LISTENER_TYPES.http_client ||
        listenerType === INGESTOR_LISTENER_TYPES.http_server ||
        listenerType === INGESTOR_LISTENER_TYPES.dbus_signal
      );
    case 'router':
      return listenerType !== INGESTOR_LISTENER_TYPES.http_client;
    case 'dbus':
      return listenerType !== INGESTOR_LISTENER_TYPES.dbus_signal;
    case 'passthrough':
      return (
        listenerType === INGESTOR_LISTENER_TYPES.tcp_client ||
        listenerType === INGESTOR_LISTENER_TYPES.dbus_signal
      );
    default:
      return false;
  }
};

export const createNewIngestorObject = (
  prop: string,
  value: string | number | string[] | boolean,
  newIngestor: Ingestor | IngestorInput,
): Ingestor | IngestorInput => {
  const propsArray = prop.split('.');
  switch (propsArray[0]) {
    case 'listener':
      return {
        ...newIngestor,
        listener: { ...newIngestor.listener, [propsArray[1]]: value },
      };
    case 'params':
      return {
        ...newIngestor,
        listener: {
          ...newIngestor.listener,
          params: { ...newIngestor.listener.params, [propsArray[1]]: value },
        },
      };
    case 'filters':
      let auxArrayFilters: ListenerFilter[] = [];
      if (newIngestor.listener.filters) {
        auxArrayFilters = [...newIngestor.listener.filters];
      }

      switch (propsArray[1]) {
        case 'path':
          auxArrayFilters[parseInt(propsArray[2])].path = value as string;
          return {
            ...newIngestor,
            listener: { ...newIngestor.listener, filters: auxArrayFilters },
          };
        case 'interface':
          auxArrayFilters[parseInt(propsArray[2])].interface = value as string;
          return {
            ...newIngestor,
            listener: { ...newIngestor.listener, filters: auxArrayFilters },
          };
        case 'member':
          auxArrayFilters[parseInt(propsArray[2])].member = value as string;
          return {
            ...newIngestor,
            listener: { ...newIngestor.listener, filters: auxArrayFilters },
          };
        default:
          return newIngestor;
      }
    case 'device_type_mapping':
      let auxArrayMapping: DeviceTypeMapping[] = [];
      if (newIngestor.listener.device_type_mapping) {
        auxArrayMapping = [...newIngestor.listener.device_type_mapping];
      }

      switch (propsArray[1]) {
        case 'device_type_id':
          auxArrayMapping[parseInt(propsArray[2])].device_type_id =
            value as string;

          return {
            ...newIngestor,
            listener: {
              ...newIngestor.listener,
              device_type_mapping: auxArrayMapping,
            },
          };
        case 'key':
          auxArrayMapping[parseInt(propsArray[2])].key = value as string;

          return {
            ...newIngestor,
            listener: {
              ...newIngestor.listener,
              device_type_mapping: auxArrayMapping,
            },
          };
        case 'value':
          auxArrayMapping[parseInt(propsArray[2])].value = value as string;
          return {
            ...newIngestor,
            listener: {
              ...newIngestor.listener,
              device_type_mapping: auxArrayMapping,
            },
          };
        default:
          return newIngestor;
      }
    case 'tags':
      return {
        ...newIngestor,
        listener: { ...newIngestor.listener, oids: value as string[] },
      };
    case 'type':
      switch (value) {
        case 'cloud':
          return {
            ...newIngestor,
            handler: {},
            handler_type: 'passthrough',
            listener: {},
            listener_type: 'cloud',
            type: value as IngestorType,
          };
        case 'edge':
          return {
            ...newIngestor,
            handler: {},
            handler_type: 'passthrough',
            listener: {},
            listener_type: 'http_server',
            type: value as IngestorType,
          };
        default:
          return newIngestor;
      }
    case 'handler':
      return {
        ...newIngestor,
        handler: { ...newIngestor.handler, [propsArray[1]]: value },
      };
    case 'routes':
      let auxArrayRoutes: IngestorRoute[] = [];
      if (newIngestor.handler.routes) {
        auxArrayRoutes = [...newIngestor.handler.routes];
      }

      switch (propsArray[1]) {
        case 'method':
          auxArrayRoutes[parseInt(propsArray[2])].methods = value as string[];
          return {
            ...newIngestor,
            handler: { ...newIngestor.handler, routes: auxArrayRoutes },
          };
        case 'methods':
          auxArrayRoutes[parseInt(propsArray[2])].methods = value as string[];
          return {
            ...newIngestor,
            handler: { ...newIngestor.handler, routes: auxArrayRoutes },
          };
        case 'path':
          auxArrayRoutes[parseInt(propsArray[2])].path = value as string;
          return {
            ...newIngestor,
            handler: { ...newIngestor.handler, routes: auxArrayRoutes },
          };
        default:
          return newIngestor;
      }
    case 'listener_type': {
      switch (value) {
        case INGESTOR_LISTENER_TYPES.bacnet:
          newIngestor.listener = BACNET_LISTENER;
          newIngestor.handler = FIXED_HANDLER;
          break;
        case INGESTOR_LISTENER_TYPES.dbus_signal:
          newIngestor.listener = DBUS_SIGNAL_LISTENER;
          newIngestor.handler = DBUS_SIGNAL_HANDLER;
          break;
        case INGESTOR_LISTENER_TYPES.dev:
          newIngestor.listener = DEV_LISTENER;
          newIngestor.handler = FIXED_HANDLER;
          break;
        case INGESTOR_LISTENER_TYPES.http_client:
          newIngestor.listener = HTTP_CLIENT_LISTENER;
          newIngestor.handler = ROUTER_HANDLER;
          break;
        case INGESTOR_LISTENER_TYPES.http_server:
          newIngestor.listener = DBUS_SIGNAL_LISTENER;
          newIngestor.handler = {};
          break;

        case INGESTOR_LISTENER_TYPES.icmp_polling:
          newIngestor.listener = ICMP_POLLING_LISTENER;
          newIngestor.handler = FIXED_HANDLER;
          break;

        case INGESTOR_LISTENER_TYPES.opcua:
          newIngestor.listener = OPCUA_LISTENER;
          newIngestor.handler = FIXED_HANDLER;
          break;

        case INGESTOR_LISTENER_TYPES.shell_polling:
          newIngestor.listener = SHELL_POLLING_LISTENER;
          newIngestor.handler = FIXED_HANDLER;
          break;

        case INGESTOR_LISTENER_TYPES.snmp_polling:
          newIngestor.listener = SNMP_POLLING_LISTENER;
          newIngestor.handler = FIXED_HANDLER;
          break;

        case INGESTOR_LISTENER_TYPES.tcp_client:
          newIngestor.listener = OPCUA_LISTENER;
          newIngestor.handler = FIXED_HANDLER;
          break;

        case INGESTOR_LISTENER_TYPES.tcp_modbus:
          newIngestor.listener = OPCUA_LISTENER;
          newIngestor.handler = FIXED_HANDLER;
          break;

        case INGESTOR_LISTENER_TYPES.tcp_server:
          newIngestor.listener = OPCUA_LISTENER;
          newIngestor.handler = FIXED_HANDLER;
          break;

        case INGESTOR_LISTENER_TYPES.udp_client:
          newIngestor.listener = OPCUA_LISTENER;
          newIngestor.handler = FIXED_HANDLER;
          break;

        case INGESTOR_LISTENER_TYPES.udp_server:
          newIngestor.listener = OPCUA_LISTENER;
          newIngestor.handler = FIXED_HANDLER;
          break;

        default:
          break;
      }
      return {
        ...newIngestor,
        handler_type: DEFAULT_HANDLER_TYPES_MAP[value as string],
        [prop]: value,
      };
    }
    default:
      return { ...newIngestor, [prop]: value };
  }
};

export const addRowsIngestorArrays = (
  prop: string,
  newIngestor: Ingestor | IngestorInput,
): Ingestor | IngestorInput => {
  switch (prop) {
    case 'device_type_mapping':
      let auxArrayMapping;

      if (Array.isArray(newIngestor.listener.device_type_mapping)) {
        auxArrayMapping = [
          ...newIngestor.listener.device_type_mapping,
          { device_type_id: '', key: '', value: '' },
        ];
      } else {
        auxArrayMapping = [{ device_type_id: '', key: '', value: '' }];
      }

      return {
        ...newIngestor,
        listener: {
          ...newIngestor.listener,
          device_type_mapping: auxArrayMapping,
        },
      };
    case 'filters':
      let auxArrayFilters;

      if (Array.isArray(newIngestor.listener.filters)) {
        auxArrayFilters = [
          ...newIngestor.listener.filters,
          {
            interface: '',
            member: '',
            path: '',
          },
        ];
      } else {
        auxArrayFilters = [
          {
            interface: '',
            member: '',
            path: '',
          },
        ];
      }

      return {
        ...newIngestor,
        listener: { ...newIngestor.listener, filters: auxArrayFilters },
      };
    case 'routes':
      let auxArrayRoutes: IngestorRoute[] = [];
      const newRoute: IngestorRoute = {
        handler_type: 'passthrough' as HandlerType,
        methods: [methodsTypes.get],
        path: '',
      };
      if (Array.isArray(newIngestor.handler.routes)) {
        auxArrayRoutes = [...newIngestor.handler.routes, newRoute];
      } else {
        auxArrayRoutes = [newRoute];
      }

      return {
        ...newIngestor,
        handler: { ...newIngestor.handler, routes: auxArrayRoutes },
      };
    default:
      return newIngestor;
  }
};

export const removeRowsIngestorArray = (
  prop: string,
  index: number,
  newIngestor: Ingestor | IngestorInput,
): Ingestor | IngestorInput => {
  switch (prop) {
    case 'device_type_mapping':
      let auxArrayMapping: DeviceTypeMapping[] = [];
      if (newIngestor.listener.device_type_mapping) {
        auxArrayMapping = [...newIngestor.listener.device_type_mapping];
      }
      auxArrayMapping.splice(index, 1);

      return {
        ...newIngestor,
        listener: {
          ...newIngestor.listener,
          device_type_mapping: auxArrayMapping,
        },
      };
    case 'filters':
      let auxArrayFilters: ListenerFilter[] = [];
      if (newIngestor.listener.filters) {
        auxArrayFilters = [...newIngestor.listener.filters];
      }
      auxArrayFilters.splice(index, 1);

      return {
        ...newIngestor,
        listener: { ...newIngestor.listener, filters: auxArrayFilters },
      };
    case 'routes':
      let auxArrayRoutes: IngestorRoute[] = [];
      if (newIngestor.handler.routes) {
        auxArrayRoutes = [...newIngestor.handler.routes];
      }
      auxArrayRoutes.splice(index, 1);

      return {
        ...newIngestor,
        handler: { ...newIngestor.handler, routes: auxArrayRoutes },
      };
    default:
      return newIngestor;
  }
};

export const isIngestorInvalid = (
  ingestor: Ingestor | IngestorInput | null,
): boolean => {
  if (ingestor) {
    const listenerType = ingestor.listener_type;
    const listener = ingestor.listener;
    const params = listener.params;
    const filters = listener.filters;
    const handlerType = ingestor.handler_type;
    const handler = ingestor.handler;

    /**
     * Disable the create button or save changes if there's no name or no account has been chosen
     * Also with all these cases clearly stated in this code block.
     */
    return (
      !ingestor.name ||
      !ingestor.company_id ||
      (listenerType === INGESTOR_LISTENER_TYPES.bacnet &&
        (!listener.host ||
          !params?.object_type ||
          !params?.property_id ||
          !params?.value)) ||
      (listenerType === INGESTOR_LISTENER_TYPES.dbus_signal &&
        (filters?.length === 0 ||
          (filters &&
            (!filters[0].interface ||
              !filters[0].member ||
              !filters[0].path)))) ||
      (listenerType === INGESTOR_LISTENER_TYPES.dev && !listener.dev) ||
      (listenerType === INGESTOR_LISTENER_TYPES.http_client &&
        (!listener.method || !listener.url)) ||
      (handlerType === 'router' && handler.routes && !handler.routes[0].path) ||
      (listenerType === INGESTOR_LISTENER_TYPES.http_server &&
        typeof listener.port === 'undefined') ||
      (listenerType === INGESTOR_LISTENER_TYPES.icmp_polling &&
        !listener.host) ||
      (listenerType === INGESTOR_LISTENER_TYPES.opcua &&
        (!listener.host || !params?.node_id || !params?.value)) ||
      (listenerType === INGESTOR_LISTENER_TYPES.shell_polling &&
        !listener.command) ||
      (listenerType === INGESTOR_LISTENER_TYPES.snmp_polling &&
        (!listener.community || !listener.host)) ||
      (listenerType === INGESTOR_LISTENER_TYPES.tcp_client &&
        !listener.address) ||
      (listenerType === INGESTOR_LISTENER_TYPES.tcp_modbus &&
        (!listener.host ||
          !listener.slave_id ||
          !params?.request_type ||
          !ingestor.listener.address ||
          !params.quantity ||
          !params.value ||
          !params.and_mask ||
          !params.or_mask)) ||
      ((listenerType === INGESTOR_LISTENER_TYPES.tcp_server ||
        listenerType === INGESTOR_LISTENER_TYPES.udp_client ||
        listenerType === INGESTOR_LISTENER_TYPES.udp_server) &&
        !listener.address) ||
      (handlerType === 'router' &&
        (handler.routes?.length === 0 ||
          (handler.routes?.length !== 0 && !handler.routes?.[0].path))) ||
      (handlerType === 'delimited' && !handler.delimited) ||
      (listenerType === INGESTOR_LISTENER_TYPES.cloud_gcp_pubsub_jci &&
        (!listener.subscription_id ||
          !listener.integration_id ||
          !listener.heartbeat_period)) ||
      ((listenerType === INGESTOR_LISTENER_TYPES.cloud_polling_assetlink ||
        listenerType === INGESTOR_LISTENER_TYPES.cloud_polling_inmarsat ||
        listenerType === INGESTOR_LISTENER_TYPES.cloud_polling_orbcomm) &&
        (!listener.access_id ||
          !listener.password ||
          typeof listener.timeout === 'undefined'))
    );
  }

  return true;
};
