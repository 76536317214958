import { GatewayCommandType } from '@edgeiq/edgeiq-api-js';
import {
  AWSIOT_ATTACH_THING_GROUPS,
  AWSIOT_DETACH_THING_GROUPS,
  CANCEL_PASSWORD_CHANGE,
  LWM2M_FILE,
  LWM2M_FILE_DOWNLOAD,
  LWM2M_FILE_UPLOAD,
  LWM2M_REQUEST,
} from '../../../app/constants';

export const SOFTWARE_UPDATE = 'software_update';

export const valueRequiredActions = ['write', LWM2M_FILE_UPLOAD];
export const valueShownActions = ['execute', 'write', LWM2M_FILE_UPLOAD];

type Action = {
  action: GatewayCommandType;
  confirm: string;
  label: string;
};

// Missing actions that are in services: send_config_v2, send_config_v3, deploy_deployment, request_deployment_status, data_restriction, update_edge
// update_firmware, restore_backup, backup, log_level, log_config, data_reset, model_restore, greengrass_restart, awsiot_attach_thing_groups, awsiot_detach_thing_groups,
// start_remote_terminal, stop_remote_terminal,
export const actions: Action[] = [
  {
    action: 'activate_cloud_native_device',
    confirm: 'Are you sure you want to activate these devices?',
    label: 'Activate Cloud Native Device',
  },
  {
    action: 'deactivate_cloud_native_device',
    confirm: 'Are you sure you want deactivate these devices?',
    label: 'Deactivate Cloud Native Device',
  },
  {
    action: 'disable_data_restriction',
    confirm: "Are you sure you'd like to enable data on all of these devices?",
    label: 'Enable Data Usage',
  },
  {
    action: 'enable_data_restriction',
    confirm: "Are you sure you'd like to disable data on all of these devices?",
    label: 'Disable Data Usage',
  },
  {
    action: 'reboot',
    confirm: "Are you sure you'd like to reboot these devices?",
    label: 'Reboot',
  },
  {
    action: CANCEL_PASSWORD_CHANGE,
    confirm:
      "Are you sure you'd like to cancel the ongoing password change for these devices?",
    label: 'Cancel Password Change',
  },
  {
    action: 'greengrass_initialize',
    confirm:
      "Are you sure you'd like to reinitialize Greengrass on all of these devices?",
    label: 'Reinitialize Greengrass',
  },
  {
    action: AWSIOT_ATTACH_THING_GROUPS,
    confirm:
      "Are you sure you'd like to add all these devices to the thing group??",
    label: 'Add to AWS IoT Thing Group',
  },
  {
    action: AWSIOT_DETACH_THING_GROUPS,
    confirm:
      "Are you sure you'd like to remove all these devices from the thing group??",
    label: 'Remove from AWS IoT Thing Group',
  },
  {
    action: 'reset',
    confirm:
      "Are you sure you'd like to reset all of these devices to their factory default settings?",
    label: 'Reset to Factory Default',
  },
  {
    action: 'heartbeat',
    confirm: 'Are you sure you want these devices to generate a heartbeat?',
    label: 'Trigger Heartbeat',
  },
  {
    action: 'status',
    confirm: 'Are you sure you want these devices to generate a status?',
    label: 'Trigger Status',
  },
  {
    action: 'send_config',
    confirm:
      "Are you sure you'd like to send the most recent configuration to these devices?",
    label: 'Update Config',
  },
  {
    action: 'log_upload',
    confirm:
      "Are you sure you'd like for these devices to upload their logs to the cloud?",
    label: 'Upload Logs to Cloud',
  },
  {
    action: 'software_update',
    confirm: "Are you sure you'd like for these devices to update?",
    label: 'Software Update',
  },
  {
    action: 'file_from_device',
    confirm:
      "Are you sure you'd like to upload the files found in path from devices to the cloud?",
    label: 'Request file from device',
  },
  {
    action: 'file_to_device',
    confirm:
      "Are you sure you'd like to download this file to these devices to the specified path?",
    label: 'Send file to device',
  },
];

export const lwm2mActions = [
  {
    action: LWM2M_REQUEST,
    label: 'Lwm2m request',
  },
  {
    action: LWM2M_FILE,
    label: 'Lwm2m file',
  },
];

export const lwm2mRequestActionTypes = [
  {
    action: 'read',
    label: 'Read',
  },
  {
    action: 'write',
    label: 'Write',
  },
  {
    action: 'execute',
    label: 'Execute',
  },
  {
    action: 'observe',
    label: 'Observe',
  },
  // {
  //   label: 'Observe and stop',
  //   action: 'observe_stop',
  // },
];

export const lwm2mFileActionTypes = [
  {
    action: LWM2M_FILE_UPLOAD,
    label: 'Upload file to device',
  },
  {
    action: LWM2M_FILE_DOWNLOAD,
    label: 'Download file from device',
  },
];

export const gatewayActions = [
  ...actions.map((action) => {
    return {
      action: action.action,
      label: action.label,
    };
  }),
  ...lwm2mActions,
  {
    action: 'setting',
    label: 'Apply Settings',
  },
  {
    action: 'setting_fetch',
    label: 'Fetch Settings',
  },
];
