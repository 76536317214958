import { createTheme, Theme } from '@mui/material';
import { Branding } from '@edgeiq/edgeiq-api-js';

import { mainBoxShadow } from './constants';

// This is the default Theme. the EIQ theme
export const AppTheme = createTheme({
  breakpoints: {
    values: {
      lg: 1250, // more than that it's large laptops and desktops
      md: 900,
      sm: 600,
      xl: 1536,
      xs: 0,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedSizeLarge: {
          height: '44px',
        },
        containedSizeMedium: {
          height: '32px',
        },
        outlinedSizeLarge: {
          fontSize: '14px',
          height: '44px',
        },
        outlinedSizeMedium: {
          height: '32px',
        },
        root: {
          textTransform: 'none',
        },
        text: {
          padding: '12px 16px',
        },
      },
    },
  },
  palette: {
    // Danger
    error: {
      dark: '#DE4F48',
      light: '#FAECEB',
      main: '#FFD4D2',
    },
    grey: {
      '100': '#1E1F21',
      '200': '#6E7176',
      '300': '#B9B9B9',
      '400': '#E4E3E3',
      '50': '#1F3849',
      '500': '#F5F5F5',
      '600': '#ECF1F6',
      '700': '#102636',
      // SIDE MENU BG COLOR
      '800': '#07131C',
      A100: '#F6F8FB',
      A200: '#ECF1F7',
      A400: '#BDD3E9',
    },
    // Equal
    info: {
      dark: '#2B4259',
      light: '#ECF1F6',
      main: '#C6CFD8',
    },
    primary: {
      contrastText: '#fff',
      main: '#40A0DB',
    },
    secondary: {
      main: '#475569',
    },
    success: {
      dark: '#609884',
      light: '#E6F8F3',
      main: '#CEE3DE',
    },
    warning: {
      dark: '#EDB31E',
      light: '#FFF4D7',
      main: '#FFE298',
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Inter, arial, sans-serif',
      textTransform: 'none',
    },
    // Body M
    button: {
      fontSize: '14px',
      lineHeight: '20px',
    },
    // Label XS
    caption: {
      fontSize: '10px',
      lineHeight: '14px',
    },
    // Heading L
    h3: {
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: '34px',
    },
    // Heading M
    h4: {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '30px',
    },
    // Heading S
    h5: {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '26px',
    },
    // Dialog title
    h6: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '20px',
    },
    // Body S - Label S
    overline: {
      fontSize: '12px',
      lineHeight: '16px',
    },
    // Body L
    subtitle1: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    // Label M
    subtitle2: {
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
});

/**
 * And here once the user loggs in we know the branding of
 * the company he/she belongs to.
 * So we use the default theme changing the primary color and other values
 */

export const CustomTheme = (branding?: Branding): Theme => {
  const theme = createTheme({
    ...AppTheme,
  });

  const primary_color = branding?.primary_color;
  const sidebar_text_color = branding?.sidebar_text_color
    ? branding?.sidebar_text_color
    : '#ffffff';
  const buttons_color = branding?.button_background_color;
  const buttons_text_color = branding?.button_text_color;

  // The primary color sets the buttons, icons, tabs, and basically almost everything in material Ui where something is highlighted.
  // It is easier to use this buttons_bg_color in our model than the primary one, so we don't have to change everywhere that the highlighted color is used.
  theme.palette.primary.main = buttons_color
    ? buttons_color
    : primary_color
    ? primary_color
    : theme.palette.primary.main;

  // The primary contrast text color sets the text in buttons mainly
  theme.palette.primary.contrastText = buttons_text_color
    ? buttons_text_color
    : sidebar_text_color
    ? sidebar_text_color
    : theme.palette.primary.contrastText;

  // We will use the secondary palette for the menu colors.
  theme.palette.secondary.main = primary_color
    ? primary_color
    : theme.palette.primary.main;

  theme.palette.secondary.contrastText = sidebar_text_color
    ? sidebar_text_color
    : theme.palette.primary.contrastText;

  theme.components = {
    ...theme.components,
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            margin: 0,
            paddingBottom: '16px',
          },
          '&.MuiPaper-root': {
            '&::before': {
              display: 'none',
            },
            borderBottom: `1px solid ${theme.palette.grey.A200}`,
            boxShadow: 'none',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          marginBottom: '24px',
          marginTop: '24px',
        },
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          padding: '12px !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        ...theme.components?.MuiButton?.styleOverrides,
        contained: {
          '&.Mui-disabled': {
            backgroundColor: theme.palette.grey[400],
            color: theme.palette.grey[300],
          },
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
          fontSize: '14px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorRight: {
          width: '650px',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        button: {
          '&.Mui-selected': {
            '& .MuiListItemIcon-root': {
              color: theme.palette.secondary.main,
            },
            backgroundColor: theme.palette.secondary.contrastText,
            borderRadius: '4px',
            color: theme.palette.secondary.main,
          },
          '&.Mui-selected:hover': {
            '& .MuiListItemIcon-root': {
              color: theme.palette.secondary.main,
            },
            backgroundColor: theme.palette.secondary.contrastText,
            borderRadius: '4px',
            color: theme.palette.secondary.main,
          },
          '&:hover': {
            // backgroundColor: theme.palette.secondary.contrastText ?? theme.palette.grey[50],
            backgroundColor: `${theme.palette.secondary.contrastText}b3`,
            borderRadius: '4px',
            color: theme.palette.secondary.main,
          },
          color: theme.palette.secondary.contrastText,
          margin: '0',
          marginLeft: '24px',
          padding: '12px 16px',
          width: 'calc(100% - 40px)',
        },
        root: {
          paddingBottom: '16px',
          paddingTop: '16px',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          '&::-webkit-scrollbar': {
            height: '7px',
            WebkitAppearance: 'none',
            width: '7px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#206F9F',
            borderRadius: '4px',
            WebkitBoxShadow: '0 0 1px rgba(255, 255, 255, 0.5)',
          },
          border: `1px solid ${theme.palette.grey.A400}`,
          borderRadius: '4px',
          boxShadow: mainBoxShadow,
          maxHeight: '290px',
          overflow: 'auto',
          padding: 0,
        },
      },
    },
    // Menus as select boxes not side menu
    MuiMenuItem: {
      styleOverrides: {
        dense: {
          '& .MuiCheckbox-root': {
            paddingLeft: 0,
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.grey.A200,
            color: theme.palette.primary.main,
          },
          '&:hover': {
            backgroundColor: theme.palette.grey.A100,
          },
          borderRadius: '4px',
          height: '36px',
          margin: '16px',
          padding: '8px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          height: '20px',
          padding: '12px',
        },
        root: {
          height: '44px',
          padding: '0 !important',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
              '& + .MuiSwitch-track': {
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? '#177ddc'
                    : theme.palette.grey[100],
                opacity: 1,
              },
              color: '#fff',
              transform: 'translateX(12px)',
            },
            padding: 2,
          },
          '& .MuiSwitch-thumb': {
            borderRadius: 6,
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            height: 12,
            transition: theme.transitions.create(['width'], {
              duration: 200,
            }),
            width: 12,
          },
          '& .MuiSwitch-track': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,.35)'
                : 'rgba(0,0,0,.25)',
            borderRadius: 16 / 2,
            boxSizing: 'border-box',
            opacity: 1,
          },
          '&:active': {
            '& .MuiSwitch-switchBase.Mui-checked': {
              transform: 'translateX(9px)',
            },
            '& .MuiSwitch-thumb': {
              width: 15,
            },
          },
          display: 'flex',
          height: 16,
          padding: 0,
          width: 28,
        },
      },
    },
  };

  return theme;
};
