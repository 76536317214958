import React, { useEffect, useState } from 'react';
import {
  Connection,
  NetworkConnection,
  IPTableAction,
  CellularAPNConfig,
} from '@edgeiq/edgeiq-api-js';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import SideTabs from '../../../../components/SideTabs';
import Accordion from '../../../../components/Accordion';
import { DEVICE_CONNECTION_TYPE } from '../../../../constants/configConnections';
import EthernetWanSection from '../DeviceConfigConnectionSection/EthernetWanSection';
import DeviceConfigConnectionCellularForm from '../DeviceConfigConnectionSection/CellularSection';
import DeviceConfigConnectionWifiForm from '../DeviceConfigConnectionSection/WifiSection';
import DeviceConfigConnectionIPTableForm from '../DeviceConfigConnectionSection/IPTableSection';

interface DeviceConfigConnectionFormProps {
  deviceNetConnections?: Connection[] | NetworkConnection[];
  iptables?: (IPTableAction | undefined)[];
  onInputChange: (
    prop: string,
    value: string | number | CellularAPNConfig[],
    field: string,
    index: number,
  ) => void;
  onAddNewIPTable?: () => void;
  onRemoveIPTableValue: (ipTableValueIndex: number) => void;
}

interface RefactoredConnection {
  connections: Connection[];
  index: number;
}

const DeviceConfigConnectionForm: React.FC<DeviceConfigConnectionFormProps> = ({
  deviceNetConnections,
  iptables,
  onInputChange,
  onAddNewIPTable,
  onRemoveIPTableValue,
}) => {
  const [connectionsMap, setConnectionsMap] = useState<{
    [key: string]: RefactoredConnection;
  }>();

  const mapConnectionsType = (): void => {
    let connectionCounts: { [key: string]: RefactoredConnection } = {};
    deviceNetConnections?.forEach((current, index) => {
      if (!connectionCounts.hasOwnProperty(current.type as string)) {
        connectionCounts = {
          ...connectionCounts,
          ...{
            [`${current.type}`]: {
              connections: [current] as Connection[],
              index,
            } as RefactoredConnection,
          },
        };
        return;
      }

      connectionCounts = {
        ...connectionCounts,
        ...{
          [current.type]: {
            connections: [
              ...connectionCounts[current.type].connections,
              ...[current],
            ] as Connection[],
            index,
          } as RefactoredConnection,
        },
      };
    });
    setConnectionsMap(connectionCounts);
  };

  useEffect(() => {
    mapConnectionsType();
  }, [deviceNetConnections]);

  const cellularComponentList = (): JSX.Element[] | JSX.Element => {
    if (connectionsMap) {
      const { connections, index } = connectionsMap.cellular || {};
      if (connections) {
        return connections.map((element) => {
          return (
            <Accordion
              key={`cellular-entry-${index}-${element.name}`}
              title={element.name}
              content={
                <DeviceConfigConnectionCellularForm
                  deviceConnection={element.config || undefined}
                  configIndex={index}
                  onInputChange={onInputChange}
                />
              }
            />
          );
        });
      }
    }

    return (
      <strong>
        This type of connection setting isn't supported for this type of device.
      </strong>
    );
  };

  const ethernetLanComponentList = (): JSX.Element[] | JSX.Element => {
    if (connectionsMap) {
      const { connections, index } = connectionsMap['ethernet-lan'] || {};
      if (connections) {
        return connections.map((element) => {
          return (
            <Accordion
              key={`ethernet-lan-entry-${index}-${element.name}`}
              title={element.name}
              content={
                <EthernetWanSection
                  connectionType={DEVICE_CONNECTION_TYPE.ETHERNET_LAN}
                  deviceConnection={element.config || undefined}
                  configIndex={index}
                  onInputChange={onInputChange}
                />
              }
            />
          );
        });
      }
    }

    return (
      <strong>
        This type of connection setting isn't supported for this type of device.
      </strong>
    );
  };

  const ethernetComponentList = (): JSX.Element[] | JSX.Element => {
    if (connectionsMap) {
      const { connections, index } = connectionsMap['ethernet-wan'] || {};

      if (connections) {
        return connections.map((element) => {
          return (
            <Accordion
              key={`ethernet-entry-${index}-${element.name}`}
              title={element.name}
              content={
                <EthernetWanSection
                  connectionType={DEVICE_CONNECTION_TYPE.ETHERNET_WAN}
                  deviceConnection={element.config || undefined}
                  configIndex={index}
                  onInputChange={onInputChange}
                />
              }
            />
          );
        });
      }
    }
    return (
      <strong>
        This type of connection setting isn't supported for this type of device.
      </strong>
    );
  };

  const ethernetWanComponentList = (): JSX.Element[] | JSX.Element => {
    if (connectionsMap) {
      const { connections, index } = connectionsMap['ethernet-wan-lan'] || {};
      if (connections) {
        return connections.map((element) => {
          return (
            <Accordion
              key={`ethernet-wan-lan-entry-${index}-${element.name}`}
              title={element.name}
              content={
                <EthernetWanSection
                  connectionType={DEVICE_CONNECTION_TYPE.ETHERNET_WAN_LAN}
                  deviceConnection={element.config || undefined}
                  configIndex={index}
                  onInputChange={onInputChange}
                />
              }
            />
          );
        });
      }
    }

    return (
      <strong>
        This type of connection setting isn't supported for this type of device.
      </strong>
    );
  };

  const wifiComponentList = (): JSX.Element[] | JSX.Element => {
    if (connectionsMap) {
      const { connections, index } = connectionsMap.wifi || {};
      if (connections) {
        return connections.map((element) => {
          return (
            <Accordion
              key={`wifi-entry-${index}-${element.name}`}
              title={element.name}
              content={
                <DeviceConfigConnectionWifiForm
                  deviceConnection={element.config || undefined}
                  configIndex={index}
                  onInputChange={onInputChange}
                />
              }
            />
          );
        });
      }
    }

    return (
      <strong>
        This type of connection setting isn't supported for this type of device.
      </strong>
    );
  };

  const ipTableComponentList = (): JSX.Element[] | JSX.Element => {
    return (
      <>
        <Button
          className="w-100"
          variant="outlined"
          size="large"
          onClick={onAddNewIPTable}
          startIcon={<AddIcon />}
          // disabled={disabled}
        >
          Add IP Table Entry
        </Button>
        {iptables?.map((element, index) => {
          return (
            <Accordion
              key={`ip-table-entry-${index}`}
              title={`IP Table Entry (${index})`}
              content={
                <DeviceConfigConnectionIPTableForm
                  deviceConnection={element || undefined}
                  configIndex={index}
                  onInputChange={onInputChange}
                  onRemoveIPTableValue={onRemoveIPTableValue}
                />
              }
            />
          );
        })}
      </>
    );
  };

  const getDefaultTab = (): string => {
    let defaultTab = 'cellular';
    if (connectionsMap?.cellular?.connections?.length) {
      defaultTab = 'cellular';
    } else if (connectionsMap?.['ethernet-lan']?.connections?.length) {
      defaultTab = 'ethernet_lan';
    } else if (connectionsMap?.['ethernet-wan']?.connections?.length) {
      defaultTab = 'ethernet_wan';
    } else if (connectionsMap?.['ethernet-wan-lan']?.connections?.length) {
      defaultTab = 'ethernet_wan_lan';
    } else if (connectionsMap?.wifi?.connections?.length) {
      defaultTab = 'wifi';
    }
    return defaultTab;
  };

  return (
    <>
      <SideTabs
        badge={{
          cellular: connectionsMap?.cellular?.connections?.length ?? 0,
          ethernet_lan:
            connectionsMap?.['ethernet-lan']?.connections?.length ?? 0,
          ethernet_wan:
            connectionsMap?.['ethernet-wan']?.connections?.length ?? 0,
          ethernet_wan_lan:
            connectionsMap?.['ethernet-wan-lan']?.connections?.length ?? 0,
          wifi: connectionsMap?.wifi?.connections?.length ?? 0,
        }}
        tabs={{
          cellular: <> {cellularComponentList()} </>,
          ethernet_lan: <> {ethernetLanComponentList()} </>,
          ethernet_wan: <> {ethernetComponentList()} </>,
          ethernet_wan_lan: <> {ethernetWanComponentList()} </>,
          ip_tables: <> {ipTableComponentList()} </>,
          wifi: <> {wifiComponentList()} </>,
        }}
        defaultTab={getDefaultTab()}
      />
    </>
  );
};

export default DeviceConfigConnectionForm;
