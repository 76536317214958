import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@mui/material';
import { BulkResponse, BulkResponses } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { setAlert } from '../../redux/reducers/alert.reducer';
import { useAppDispatch } from '../../redux/hooks';
import { errorHighlight } from '../../app/constants';
import timeHelpers from '../../helpers/timeHelpers';
import { getBulkResponseStatus } from '../../helpers/bulkJobs';

const BulkJobsChart: React.FC = () => {
  const [bulkJobsChartData, setBulkJobsChartData] = useState<BulkResponse[]>(
    [],
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    BulkResponses.list({}, { itemsPerPage: 6, page: 1 })
      .then((response) => {
        setBulkJobsChartData(response.bulkResponses);
      })
      .catch((error) => {
        dispatchError(error.message);
      });
  }, []);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const handleGoBulkJobs = (): void => {
    navigate(`/messages#bulk-jobs`);
  };

  return (
    <div className="d-flex flex-items-stretch flex-column">
      <Typography
        variant="h5"
        className={clsx('mb-4 d-flex flex-justify-between pb-4 pt-4')}
      >
        Bulk Jobs
        <Button
          className="p-0"
          color="primary"
          variant="text"
          type="button"
          onClick={handleGoBulkJobs}
        >
          <Typography variant="button">View More</Typography>
        </Button>
      </Typography>

      <div>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Date/Time</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Successes</TableCell>
                <TableCell>Failures</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bulkJobsChartData.map((row) => (
                <TableRow
                  key={row._id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell>
                    {row.created_at && (
                      <>{timeHelpers.getDate(row.created_at, false, false)}</>
                    )}
                  </TableCell>
                  <TableCell>
                    {getBulkResponseStatus(row as BulkResponse)}
                  </TableCell>
                  <TableCell>{row.total}</TableCell>
                  <TableCell>
                    <Typography color={row.successes > 0 ? '#609884' : ''}>
                      {row.successes}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color={row.failures > 0 ? '#DE4F48' : ''}>
                      {row.failures}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default BulkJobsChart;
