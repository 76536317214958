import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Paper, Typography } from '@mui/material';
import { Secrets, SecretInput } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch } from '../../redux/hooks';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../app/constants';
import Publish from '../../containers/Publish';
import TextInput from '../../components/TextInput';
import Header from '../../containers/HeaderWithActionButton';
import useStyles from './styles';

const EmptySecret: SecretInput = {
  company_id: '',
  content: '',
  name: '',
};

const CreateSecretPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [newSecret, setNewSecret] = useState<SecretInput>(EmptySecret);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const checkSubmitEnable = (): void => {
    setEnableSubmit(
      newSecret.name !== '' &&
        newSecret.content !== '' &&
        newSecret.company_id !== '',
    );
  };

  useEffect(() => {
    checkSubmitEnable();
  }, [newSecret]);

  const handleSecretChange = (
    prop: string,
    value: string | number | string[],
  ): void => {
    setNewSecret({ ...newSecret, [prop]: value });
  };

  const handleOnAccountChange = (companyId: string): void => {
    handleSecretChange('company_id', companyId);
  };

  const handlePublishSubmit = (): void => {
    setSubmitting(true);
    Secrets.create(newSecret)
      .then((_result) => {
        dispatch(
          setAlert({
            highlight: 'Secret created successfully',
            type: 'success',
          }),
        );
        navigate('/secrets');
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.messages || error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Grid container direction="row" spacing={3} className="p-9">
      <Grid item xs={12}>
        <Header
          goBack="secrets"
          goBackLabel="Secrets"
          isCreatePage={true}
          model="secret"
        />
      </Grid>
      <Grid item xs={8}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <Paper className="shadow p-8 ">
              <div className={clsx('mb-6', classes.titleContainer)}>
                <Typography
                  data-cy="create-secret-title"
                  variant="h5"
                  className={classes.title}
                >
                  Create a new secret
                </Typography>
              </div>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={6} className="mt-6">
                  <TextInput
                    label="Name"
                    prop="name"
                    required={true}
                    value={newSecret.name}
                    onInputChange={handleSecretChange}
                  />
                </Grid>
                <Grid item xs={6} className="mt-6">
                  <TextInput
                    label="Content"
                    prop="content"
                    required={true}
                    value={newSecret.content}
                    onInputChange={handleSecretChange}
                  />
                </Grid>
              </Grid>
              <div className={clsx(classes.formBottom)}></div>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Publish
          label="secret"
          submitting={submitting}
          companyId={newSecret.company_id}
          onChangeAccount={handleOnAccountChange}
          onSubmit={handlePublishSubmit}
          enableSubmit={enableSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default CreateSecretPage;
