import { Integration, IntegrationInput } from '@edgeiq/edgeiq-api-js';

export const verifySubmitEnable = (
  newIntegration: Integration | IntegrationInput,
): boolean => {
  if (!newIntegration) {
    return false;
  }
  switch (newIntegration.type) {
    case 'aws_device_integrations':
      return (
        newIntegration.region !== '' &&
        newIntegration.external_id !== '' &&
        newIntegration.role_arn !== '' &&
        newIntegration.greengrass_core_install_url !== ''
      );

    case 'azure_device_integrations':
      return (
        newIntegration.iot_hub_hostname !== '' &&
        newIntegration.shared_access_key !== '' &&
        newIntegration.access_policy_name !== ''
      );
    case 'gcp_cloud_native_device_integrations':
      return (
        newIntegration.gcp_service_account_key_secret_id !== '' &&
        newIntegration.gcp_project_id !== ''
      );
    case 'gs_cloud_native_device_integrations':
      return (
        newIntegration.gs_username !== '' &&
        newIntegration.gs_password !== '' &&
        newIntegration.gs_customer_id !== '' &&
        newIntegration.gs_rate_plan_id !== '' &&
        newIntegration.gs_address_id !== ''
      );
    case 'inmarsat_cloud_native_device_integrations':
      return (
        newIntegration.inmarsat_client_id !== '' &&
        newIntegration.inmarsat_secret !== '' &&
        newIntegration.inmarsat_username !== '' &&
        newIntegration.inmarsat_password !== '' &&
        newIntegration.inmarsat_customer_id !== '' &&
        newIntegration.inmarsat_scap_id !== '' &&
        newIntegration.inmarsat_industry_code !== '' &&
        newIntegration.inmarsat_mailbox_id !== ''
      );
    case 'orbcomm_cloud_native_device_integrations':
      return (
        newIntegration.orbcomm_username !== '' &&
        newIntegration.orbcomm_password !== '' &&
        newIntegration.orbcomm_solution_provider_id !== '' &&
        newIntegration.orbcomm_gateway_account_id !== ''
      );
    case 'postmark_rule_action_integrations':
      return newIntegration.token !== '';
    case 'smtp_rule_action_integrations':
      return (
        newIntegration.server !== '' &&
        newIntegration.username !== '' &&
        newIntegration.password !== ''
      );
    case 'twilio_rule_action_integrations':
      return (
        newIntegration.sid !== '' &&
        newIntegration.token !== '' &&
        newIntegration.from !== ''
      );
    case 'generic_username_password_integrations':
      return newIntegration.username !== '' && newIntegration.password !== '';
    case 'generic_token_integrations':
      return newIntegration.token !== '';
  }
};
