import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    info: {
      alignItems: 'center',
      color: theme.palette.grey[200],
      display: 'flex',
    },
    infoIcon: {
      color: theme.palette.grey[200],
      verticalAlign: 'middle',
    },
    labelContainer: {
      [theme.breakpoints.down('lg')]: {
        paddingLeft: '0 !important',
      },
    },
  }),
);

export default useStyles;
