import React from 'react';
import { DeviceType } from '@edgeiq/edgeiq-api-js';
import { Box } from '@mui/material';
import clsx from 'clsx';

import SwitchButton from '../../../../components/SwitchButton';

interface DeviceTypeOptionsProps {
  deviceType: DeviceType;
  onSwitchChange: (prop: string, value: boolean) => void;
}

const DeviceTypeOptions: React.FC<DeviceTypeOptionsProps> = ({
  deviceType,
  onSwitchChange,
}) => (
  <Box className={clsx('display-grid')}>
    {deviceType && (
      <>
        <SwitchButton
          label="Support Firmware Upgrades"
          value={deviceType.capabilities.firmware.upgrade}
          prop="firmware-upgrade"
          onSwitchChange={onSwitchChange}
        />
        <SwitchButton
          label="Support IP Tables"
          value={deviceType.capabilities.iptables}
          prop="ip-tables"
          onSwitchChange={onSwitchChange}
        />
      </>
    )}
  </Box>
);

export default DeviceTypeOptions;
