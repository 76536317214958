import { CellularAPNConfig, ConnectionConfig } from '@edgeiq/edgeiq-api-js';

export const EMPTY_CONFIG_CONNECTION = {
  net_broadcast: '',
  net_dns_1: '',
  net_dns_2: '',
  net_enable: 0,
  net_gateway: '',
  net_ip: '',
  net_ip_assign: '',
  net_mask: '',
  net_metric: 1,
  net_mtu: 1500,
  net_nat: 0,
};

export const EMPTY_CONFIG_CONNECTION_WAN = {
  net_broadcast: '',
  net_dns_1: '',
  net_dns_2: '',
  net_enable: 0,
  net_gateway: '',
  net_ip: '',
  net_ip_assign: 'static',
  net_mask: '',
  net_metric: 1,
  net_mtu: 1500,
  net_nat: 0,
};

export const EMPTY_CONFIG_CONNECTION_WANLAN = {
  ...EMPTY_CONFIG_CONNECTION_WAN,
  ...{
    dhcp_dns1: '',
    dhcp_dns2: '',
    dhcp_enable: 0,
    dhcp_end: '',
    dhcp_lease: 1,
    dhcp_relay: 0,
    dhcp_start: '',
    net_mode: 'LAN',
  },
};

export const EMPTY_CONFIG_CONNECTION_LAN = {
  ...EMPTY_CONFIG_CONNECTION_WANLAN,
  ...{
    net_ip_assign: '',
    net_mode: '',
  },
};

export const EMPTY_CONFIG_CONNECTION_CELULAR: ConnectionConfig = {
  cell_apns: [],
  cell_auto_apn: 1,
  cell_modem_preferred_network_type: 'AUTO',
  cell_modem_serial_dev: '',
  cell_pass: '',
  cell_user: '',
  net_enable: 1,
};

export const EMPTY_CONFIG_CONNECTION_WIFI = {
  net_broadcast: '',
  net_dns_1: '',
  net_dns_2: '',
  net_gateway: '',
  net_ip: '',
  net_ip_assign: 'static',
  net_mask: '',
  wifi_auth: 'wpa2psk',
  wifi_channel: 1,
  wifi_crypt: 'aes',
  wifi_enable: 0,
  wifi_hidden: 0,
  wifi_mode: 'accesspoint',
  wifi_psk: '',
  wifi_ssid: '',
};

export const EMPTY_IP_TABLE = {
  action: '',
  chain: '',
  destination: '',
  destination_port: '',
  in_interface: '',
  jump: '',
  out_interface: '',
  protocol: '',
  source: '',
  source_port: '',
  table: '',
};

export const DEVICE_CONNECTION_TYPE = {
  CELLULAR: 'cellular',
  ETHERNET_LAN: 'ethernet-lan',
  ETHERNET_WAN: 'ethernet-wan',
  ETHERNET_WAN_LAN: 'ethernet-wan-lan',
  WIFE: 'wife',
};

export const DEFAULT_APN_CONFIG: CellularAPNConfig = {
  cell_apn_auth: 'none',
  cell_apn_bearer: [],
  cell_apn_default: true,
  cell_apn_mvno_type: '',
  cell_apn_mvno_value: '',
  cell_apn_name: '',
  cell_apn_protocol: 'IPv4',
  cell_apn_proxy: '',
  cell_apn_roaming: false,
  cell_apn_roaming_protocol: 'IPv4',
  cell_apn_server: '',
  cell_apn_type: 'default',
};

export const ASSIGN_LABELS: {
  [key: string]: string;
} = {
  auto: 'Auto',
  static: 'Static',
};

export const NET_MODE_LABELS: {
  [key: string]: string;
} = {
  LAN: 'LAN',
  WAN: 'WAN',
};

export const CONNECTION_TIME_LABELS: {
  [key: string]: string;
} = {
  Days: 'Days',
  Hours: 'Hours',
  Minutes: 'Minutes',
  Seconds: 'Seconds',
};
export const CEL_AUTH_TYPES: {
  [key: string]: string;
} = {
  CHAP: 'CHAP',
  none: 'None',
  PAP: 'PAP',
  PAP_CHAP: 'PAP / CHAP',
};
export const PREFERRED_NETWORK_TYPES: {
  [key: string]: string;
} = {
  '2G': '2G',
  '3G': '3G',
  '5G': '5G',
  AUTO: 'auto',
  LTE: 'LTE',
};
export const CALL_APN_TYPES: {
  [key: string]: string;
} = {
  cbs: 'CBS',
  default: 'Default',
  fota: 'FOTA',
  hipri: 'HIPRI',
  mms: 'MMS',
  supl: 'SUPL',
  xcap: 'XCAP',
};
export const CALL_APN_PROTOCOLES: {
  [key: string]: string;
} = {
  IPv4: 'IPv4',
  IPv4_IPv6: 'IPv4 / IPv6',
  IPv6: 'IPv6',
};
export const CALL_APN_BEARER: {
  [key: string]: string;
} = {
  '1xRTT': '1xRTT',
  EDGE: 'EDGE',
  eHRPD: 'eHRPD',
  EVDO_0: 'EVDO_0',
  EVDO_A: 'EVDO_A',
  EVDO_B: 'EVDO_B',
  GPRS: 'GPRS',
  HSPA: 'HSPA',
  HSPAP: 'HSPAP',
  HSUPA: 'HSUPA',
  IS95A: 'IS95A',
  IS95B: 'IS95B',
  LTE: 'LTE',
  NR: 'NR',
  UMTS: 'UMTS',
  unspecified: 'Unspecified',
};
export const WIFI_AUTH_TYPES: {
  [key: string]: string;
} = {
  wpa2psk: 'WPA2-PSK',
  wpapsk: 'WPA-PSK',
};
export const WIFI_ENCYPTION_TYPES: {
  [key: string]: string;
} = {
  aes: 'AES',
  tkip: 'TKIP',
};

export const WIFI_MODES: {
  [key: string]: string;
} = {
  accesspoint: 'Access Point',
  client: 'Client',
};
export const TABLE_ENTRY_ACTIONS: {
  [key: string]: string;
} = {
  append: 'Append',
  flush: 'Flush',
};
export const TALBE_ENTRY_TYPES: {
  [key: string]: string;
} = {
  filter: 'Filter',
  mangle: 'Mangle',
  nat: 'NAT',
  raw: 'Raw',
  security: 'Security',
};
export const TABLE_ENTRY_PROTOCOLS: {
  [key: string]: string;
} = {
  tcp: 'TCP',
  udp: 'UDP',
};
