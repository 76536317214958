import { Grid, MenuItem, Paper, Typography } from '@mui/material';
import clsx from 'clsx';
import { Translators, TranslatorInput } from '@edgeiq/edgeiq-api-js';
import { useNavigate } from 'react-router-dom';
import { ChangeEvent, useEffect, useState } from 'react';

import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import {
  cleanNewTranslator,
  setNewTranslator,
} from '../../../redux/reducers/translators.reducer';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { RootState } from '../../../redux/store';
import { errorHighlight } from '../../../app/constants';
import Header from '../../../containers/HeaderWithActionButton';
import Publish from '../../../containers/Publish';
import TextInput from '../../../components/TextInput';
import SelectInput from '../../../components/SelectInput';
import {
  TRANSLATOR_TYPES,
  TRANSLATOR_JAVASCRIPT_TYPE,
  TRANSLATOR_TEMPLATE_TYPE,
  EMPTY_TRANSLATOR,
  TEXT_AREA_TYPES,
} from '../../../constants/translators';
import useStyles from './styles';

const CreateTranslator: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const translatorState = useAppSelector(
    (state: RootState) => state.translators,
  );
  const { newTranslator } = translatorState;

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    dispatch(setNewTranslator(EMPTY_TRANSLATOR));
  }, []);

  const handleChange = (
    prop: string,
    value: string | number | string[],
  ): void => {
    dispatch(
      setNewTranslator({
        ...newTranslator,
        [prop]: value,
      } as TranslatorInput),
    );
  };

  const handleTypeChange = (_prop: string, type: string): void => {
    let script = '';
    switch (type) {
      case 'javascript':
        script = TRANSLATOR_JAVASCRIPT_TYPE;
        break;

      case 'template':
        script = TRANSLATOR_TEMPLATE_TYPE;
        break;

      default:
        script = '';
        break;
    }
    dispatch(
      setNewTranslator({
        ...newTranslator,
        script,
        type,
      } as TranslatorInput),
    );
  };

  const handleAccountChange = (companyId: string): void => {
    dispatch(
      setNewTranslator({
        ...newTranslator,
        company_id: companyId,
      } as TranslatorInput),
    );
  };

  const isEnableToSubmit = (): boolean => {
    return newTranslator?.name !== '' && newTranslator?.company_id !== '';
  };

  const submitHandler = (): void => {
    setIsSubmitting(true);
    Translators.create(newTranslator as TranslatorInput)
      .then((_result) => {
        dispatch(
          setAlert({
            highlight: 'Translator created successfully',
            type: 'success',
          }),
        );
        dispatch(cleanNewTranslator());
        navigate('/data-management');
      })
      .catch((error) => {
        setAlert({
          highlight: errorHighlight,
          message: error.messages || error.message,
          type: 'error',
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleTemplateChange = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    handleChange('script', event.target.value);
  };

  const { javascript, template } = TEXT_AREA_TYPES;
  const inputTextAreaTypes: Record<string, string> = Object.assign(
    {},
    { javascript, template },
  );

  return (
    <Grid container direction="row" spacing={3} className="p-9">
      <Grid item xs={12}>
        <Header
          goBack="data-management#translators"
          goBackLabel="Translators"
          isCreatePage={true}
          model="translator"
        />
      </Grid>
      <Grid item xs={8}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <Paper className="shadow p-8 ">
              <div className={clsx('mb-6', classes.titleContainer)}>
                <Typography
                  data-cy="create-translator-title"
                  variant="h5"
                  className={classes.title}
                >
                  Create a new translator
                </Typography>
              </div>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} className="mt-6">
                  <TextInput
                    label="Name"
                    prop="name"
                    required={true}
                    value={newTranslator?.name}
                    onInputChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} className="mt-6">
                  <SelectInput
                    label="Type"
                    prop="type"
                    required={true}
                    value={newTranslator?.type}
                    onSelectChange={handleTypeChange}
                    options={TRANSLATOR_TYPES.map((type) => (
                      <MenuItem dense key={type.key} value={type.key}>
                        {type.label}
                      </MenuItem>
                    ))}
                  />
                </Grid>
                {inputTextAreaTypes[newTranslator?.type ?? ''] && (
                  <Grid
                    container
                    direction="column"
                    spacing={3}
                    className={clsx(classes.container)}
                  >
                    <Grid
                      item
                      xs={12}
                      className={clsx('mt-6', classes.textArea)}
                    >
                      <textarea
                        style={{
                          height: '350px',
                          marginLeft: '16px',
                          width: '100%',
                        }}
                        value={newTranslator?.script}
                        onChange={handleTemplateChange}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Publish
          label="discovered device"
          submitting={isSubmitting}
          companyId={newTranslator?.company_id ?? ''}
          onChangeAccount={handleAccountChange}
          onSubmit={submitHandler}
          enableSubmit={isEnableToSubmit()}
        />
      </Grid>
    </Grid>
  );
};

export default CreateTranslator;
