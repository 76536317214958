import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkButton: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  }),
);

export default useStyles;
