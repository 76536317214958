import { CommandInput } from '@edgeiq/edgeiq-api-js';

export const EMPTY_COMMAND: CommandInput = {
  company_id: '',
  device_id: '',
  long_description: '',
  name: '',
  options: {},
  payload: '',
  sender: {},
  sender_type: 'tcp_sender',
  translator_id: '',
};
