import { Typography } from '@mui/material';
import { BulkResponse } from '@edgeiq/edgeiq-api-js';
import { TableColumn, TableItemType } from '../../../components/SharedTable';
import timeHelpers from '../../../helpers/timeHelpers';
import { formatReadableActionName } from '../../../helpers/utils';
import { getBulkResponseStatus } from '../../../helpers/bulkJobs';

export const BulkJobsColumns = (
  handleActionClick: (item: BulkResponse, action: 'details') => void,
): TableColumn[] => {
  return [
    {
      cellValue: (item: TableItemType): string => {
        const bulkResponse = item as BulkResponse;
        return `${timeHelpers.getDate(bulkResponse.created_at)}`;
      },
      id: 'created_at',
      label: 'Date',
      type: 'text',
    },
    {
      id: 'status',
      label: 'Status',
      parser: (item: TableItemType): string | React.ReactElement => {
        return getBulkResponseStatus(item as BulkResponse);
      },
      type: 'custom',
    },
    {
      cellValue: (item: TableItemType): string => {
        const bulkResponse = item as BulkResponse;
        return `${bulkResponse.successes}`;
      },
      id: 'successes',
      label: 'Successes',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const bulkResponse = item as BulkResponse;
        return `${bulkResponse.failures}`;
      },
      id: 'failues',
      label: 'Failures',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const bulkResponse = item as BulkResponse;
        return formatReadableActionName(bulkResponse.type);
      },
      id: 'type',
      label: 'Type',
      type: 'text',
    },
    {
      id: 'details',
      label: 'Details',
      parser: (item: TableItemType): React.ReactElement => {
        const bulkResponse = item as BulkResponse;
        return (
          <Typography
            variant="button"
            style={{ cursor: 'pointer' }}
            onClick={(): void => handleActionClick(bulkResponse, 'details')}
          >
            View Details
          </Typography>
        );
      },
      type: 'custom',
    },
  ];
};
