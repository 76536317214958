import React, { useState, useEffect } from 'react';
import { Grid, MenuItem, Typography } from '@mui/material';
import {
  ScheduledJob,
  ScheduledJobInput,
  Command,
  Commands,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { setAlert } from '../../../redux/reducers/alert.reducer';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setStateCommands } from '../../../redux/reducers/commands.reducer';
import {
  errorHighlight,
  optionsPaginationsFilter,
  scheduledJobTypes,
} from '../../../app/constants';
import TextInput from '../../../components/TextInput';
import SelectInput from '../../../components/SelectInput';
import CronSpec from '../../../components/CronSpec';
import SwitchButton from '../../../components/SwitchButton';
import { actions } from '../../RightDrawer/IssueCommand/constants';
import useStyles from './styles';

interface ScheduledJobsFormProps {
  newScheduledJob: ScheduledJob | ScheduledJobInput;
  invalidOptionJson?: boolean;
  disabled?: boolean;
  hardResetCronSpec?: boolean;
  originalCronSpec?: string;
  onInputChange: (prop: string, value: string | number | boolean) => void;
  setCronSpecInvalid: React.Dispatch<React.SetStateAction<boolean>>;
}
//NOTE: Uncomment this helper function when metadata input gets reimplemented.
/*
const prettifyJSON = (scheduledJob?: { [key: string]: unknown }): string =>
  JSON.stringify(scheduledJob || {}, null, 3);
*/

const ScheduledJobsForm: React.FC<ScheduledJobsFormProps> = ({
  newScheduledJob,
  //invalidOptionJson: invalidSenderJson,
  disabled,
  hardResetCronSpec = false,
  originalCronSpec,
  onInputChange,
  setCronSpecInvalid,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const stateCommands = useAppSelector((state: RootState) => state.commands);
  const [commands, setCommands] = useState<Command[]>(stateCommands.commands);

  useEffect(() => {
    if (commands.length === 0) {
      Commands.list({}, optionsPaginationsFilter)
        .then((result) => {
          setCommands(result.commands);
          dispatch(setStateCommands(result.commands));
        })
        .catch((error) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: error.message,
              type: 'error',
            }),
          );
        });
    }
  }, []);

  //NOTE: Uncomment these functions when metadata input feature is reimplemented.
  /*
  const onLocalHandlerChange = (prop: string, value: string | number): void => {
      switch (prop) {
        case 'options':
          setOptions(value as string);
          break;
        default:
          break;
      }

      onInputChange(prop, value);
    };

    useEffect(() => {
      if (newScheduledJob.job_type) {
        setOptions(
          prettifyJSON(
            newScheduledJob.job_type === 'user-defined-command'
              ? newScheduledJob.details.command_options
              : newScheduledJob.details.workflow_options,
          ),
        );
      } else {
        setOptions('{}');
      }
    }, [newScheduledJob]);
*/

  const renderOptions = (): JSX.Element[] => {
    return commands.map((command) => (
      <MenuItem className="m-4 p-2" dense key={command._id} value={command._id}>
        {command.name}
      </MenuItem>
    ));
  };

  const renderGatewayCommandsOptions = (): JSX.Element[] => {
    return actions.map((command) => (
      <MenuItem
        className="m-4 p-2"
        dense
        key={command.action}
        value={command.action}
      >
        {command.label}
      </MenuItem>
    ));
  };

  const onUpdateCronValue = (value: string): void => {
    onInputChange('schedule', value);
  };

  return (
    <Grid container direction="row" spacing={2}>
      {/* Row 1 */}
      <Grid item xs={12} md={6} className="mt-6">
        <TextInput
          label="Job Name"
          prop="job_name"
          required={true}
          value={newScheduledJob.job_name}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={6} className="mt-6 d-flex flex-items-center">
        <SwitchButton
          label="Job Enabled"
          value={newScheduledJob.enabled}
          prop="enabled"
          onSwitchChange={onInputChange}
        />
      </Grid>
      <Grid item xs={12} md={6} className="mt-6">
        <SelectInput
          label="Job Type"
          prop="job_type"
          required={true}
          value={newScheduledJob.job_type}
          onSelectChange={onInputChange}
          disabled={disabled}
          options={Object.keys(scheduledJobTypes).map((key, index) => (
            <MenuItem className="m-4 p-2" key={index} dense value={key}>
              {scheduledJobTypes[key]}
            </MenuItem>
          ))}
        />
      </Grid>
      {newScheduledJob.job_type === 'user-defined-command' && (
        <Grid item xs={12} md={6} className="mt-6">
          <SelectInput
            label="Command"
            prop="command_id"
            value={newScheduledJob.command_id}
            onSelectChange={onInputChange}
            disabled={disabled}
            options={[
              <MenuItem dense value="" key="no-value-command">
                Select a command
              </MenuItem>,
              ...renderOptions(),
            ]}
          />
        </Grid>
      )}
      {newScheduledJob.job_type === 'gateway-command' && (
        <Grid item xs={12} md={6} className="mt-6">
          <SelectInput
            label="Gateway Command"
            prop="command_type"
            value={
              (
                newScheduledJob.details.command_options
                  ?.gateway_command_request as unknown as Record<string, string>
              )?.command_type || ''
            }
            onSelectChange={onInputChange}
            disabled={disabled}
            options={[
              <MenuItem dense value="" key="no-value-command">
                Select a gateway command
              </MenuItem>,
              ...renderGatewayCommandsOptions(),
            ]}
          />
        </Grid>
      )}
      <Grid item xs={12} className="mt-6">
        <Typography
          variant="button"
          component="div"
          className={clsx('mb-1', classes.configTitle)}
        >
          Schedule (cron) *
        </Typography>
        <CronSpec
          hardReset={hardResetCronSpec}
          defaultValue={originalCronSpec}
          onUpdateValue={onUpdateCronValue}
          setCronSpecInvalid={setCronSpecInvalid}
        />
      </Grid>
    </Grid>
  );
};

export default ScheduledJobsForm;
