import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';

import { messagesTabsLabels } from '../../app/constants';
import Header from '../../components/Header';
import VerticalTabs from '../../components/VerticalTabs';
import { getPageHash } from '../../helpers/utils';
import Heartbeats from './heartbeatsMessages/HeartbeatsMessages';
import ReportsPage from './reportsMessages/ReportsMessages';
import GatewayCommandsPage from '../../containers/GatewayCommandsMessages';
import CommandsExecutiuonsPage from './commandsMessages/CommandsExecutionsMessages';
import DeviceErrorsPage from './deviceErrorsMessages/DeviceErrorsMessages';
import IntegrationEventsPage from './integrationEventsMessages/IntegrationEventsMessages';
import SettingsApplicationRecord from './settingApplicationMessages/SettingsApplicationRecord';
import AlertsMessages from './alerts/AlertsMessages';
import BulkJobs from './bulkJobs/BulkJobs';

const defaultTab = 'bulk_jobs';

const MessagesPage: React.FC = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(defaultTab);

  useEffect(() => {
    setActiveTab(getPageHash(location.hash, defaultTab, messagesTabsLabels));
  }, [location.hash]);

  return (
    <Grid container direction="column" spacing={0}>
      <Header title="Messages" />
      <VerticalTabs
        changeUrl={true}
        tabs={{
          /* eslint sort-keys: 0 */
          bulk_jobs: <BulkJobs />,
          commands_executions: <CommandsExecutiuonsPage />,
          device_errors: <DeviceErrorsPage />,
          gateway_commands: <GatewayCommandsPage />,
          heartbeats: <Heartbeats />,
          settings_application_records: <SettingsApplicationRecord />,
          integration_events: <IntegrationEventsPage />,
          reports: <ReportsPage />,
          alerts: <AlertsMessages />,
        }}
        defaultTab={activeTab}
        tabsLabel={messagesTabsLabels}
      />
    </Grid>
  );
};

export default MessagesPage;
