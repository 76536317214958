import React, { useState, useEffect, useRef } from 'react';
import { Grid, MenuItem, Typography } from '@mui/material';
import {
  Command,
  CommandInput,
  Translators,
  Workflows,
} from '@edgeiq/edgeiq-api-js';

import { setAlert } from '../../../redux/reducers/alert.reducer';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

import { RootState } from '../../../redux/store';
import { setOptionsWorkflows } from '../../../redux/reducers/workflows.reducer';
import { setTranslatorsOptions } from '../../../redux/reducers/translators.reducer';
import {
  commandsSenderTypes,
  errorHighlight,
  optionsPaginationsFilter,
} from '../../../app/constants';
import TextInput from '../../../components/TextInput';
import SelectInput from '../../../components/SelectInput';
import CheckboxInput from '../../../components/CheckboxInput';
import CodeEditor from '../../../components/CodeEditor';
import OptionsForm from './OptionsForm';
import SenderCommandForm from './SenderCommandForm';

interface CommandFormProps {
  newCommand: Command | CommandInput;
  invalidSenderJson?: boolean;
  invalidOptionJson?: boolean;
  onInputChange: (prop: string, value: string | number) => void;
  onDynamicChange: (
    prop: string,
    value: string | number,
    field: string,
    index: string,
  ) => void;
  onAddRow?: (prop: string) => void;
  onRemoveRow?: (prop: string, item: string) => void;
  disabled?: boolean;
  shellCommand: string;
}

const prettifyJSONCommand = (command?: { [key: string]: unknown }): string =>
  JSON.stringify(command || {}, null, 3);

const CommandForm: React.FC<CommandFormProps> = ({
  newCommand,
  onInputChange,
  onDynamicChange,
  onAddRow,
  onRemoveRow,
  disabled,
  shellCommand,
}) => {
  const dispatch = useAppDispatch();
  const [sender, setSender] = useState('');
  const [optionsCustomJson, setOptionsCustomJson] = useState('');
  const anchorRef = useRef<HTMLDivElement>(null);
  const translatorsState = useAppSelector(
    (state: RootState) => state.translators,
  );
  const workflowsState = useAppSelector((state: RootState) => state.workflows);
  const [translatorsOptionsList, setTranslatorsOptionsList] = useState(
    translatorsState.optionsTranslators || [],
  );
  const [workflowsOptionsList, setWorkflowsOptionsList] = useState(
    workflowsState.optionsWorkflows || [],
  );

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const onLocalHandlerChange = (prop: string, value: string | number): void => {
    switch (prop) {
      case 'sender':
        setSender(value as string);
        break;
      case 'options':
        setOptionsCustomJson(value as string);
        break;
      default:
        break;
    }

    onInputChange(prop, value);
  };

  useEffect(() => {
    if (workflowsState.optionsWorkflows?.length) {
      setWorkflowsOptionsList(workflowsState.optionsWorkflows);
    } else {
      getWorkflows();
    }
  }, [workflowsState]);

  useEffect(() => {
    if (translatorsState.optionsTranslators?.length) {
      setTranslatorsOptionsList(translatorsState.optionsTranslators);
    } else {
      getTraslators();
    }
  }, [translatorsState]);

  useEffect(() => {
    setSender(prettifyJSONCommand(newCommand.sender));
    setOptionsCustomJson(prettifyJSONCommand(newCommand.options));
  }, [newCommand]);

  const getWorkflows = (): void => {
    Workflows.list({}, optionsPaginationsFilter)
      .then((result) => {
        dispatch(setOptionsWorkflows(result.workflows));
      })
      .catch((error) => {
        dispatchError(error.message);
      });
  };

  const getTraslators = (): void => {
    Translators.list({}, optionsPaginationsFilter)
      .then((result) => {
        dispatch(setTranslatorsOptions(result.translators));
      })
      .catch((error) => {
        dispatchError(error.message);
      });
  };

  const formattedJsonOption = (
    command: Command | CommandInput,
  ): { [key: string]: unknown }[] => {
    if (!command?.options) {
      return [];
    }
    return Object.keys(command?.options as { [key: string]: unknown }).map(
      (item) => {
        let objResult = {};
        const commandOptions = command?.options?.[item] as {
          [key: string]: unknown;
        };
        objResult = {
          ...objResult,
          ...{
            default: commandOptions.default,
            [`key`]: item,
            type: commandOptions.type,
          },
        };
        return objResult;
      },
    );
  };

  // const handleJsonContentChange = (prop: string, value: string): void => {
  //   onInputChange(prop, value);
  // };

  return (
    <Grid container direction="row" spacing={2} ref={anchorRef}>
      {/* Row 1 */}
      <Grid item xs={12} lg={6} className="mt-6">
        <TextInput
          label="Command Name"
          prop="name"
          required={true}
          value={newCommand.name}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} lg={6} className="mt-6">
        <SelectInput
          label="Sender Type"
          prop="sender_type"
          required={true}
          value={newCommand.sender_type}
          onSelectChange={onInputChange}
          disabled={disabled}
          options={Object.keys(commandsSenderTypes).map((key, index) => (
            <MenuItem className="m-4 p-2" key={index} dense value={key}>
              {commandsSenderTypes[key]}
            </MenuItem>
          ))}
        />
      </Grid>

      {newCommand.sender_type === 'shell_sender' && (
        <Grid item xs={12} className="mt-6">
          <TextInput
            label="Command"
            prop="shellCommand"
            value={shellCommand}
            onInputChange={onInputChange}
            disabled={disabled}
          />
          <Typography variant="subtitle2" className="mt-2">
            Please fill out the following inputs for the sender type you have
            selected.
          </Typography>
        </Grid>
      )}

      {newCommand.sender_type !== 'workflow_sender' ? (
        <>
          <Grid item xs={12} lg={6} className="mt-6">
            <CheckboxInput
              label="Save command output"
              prop="save_command_output"
              value="save_command_output_value"
              checked={newCommand.save_command_output ?? false}
              onCheckboxClick={onInputChange}
            />
          </Grid>

          <Grid item xs={12} lg={6} className="mt-6">
            <CheckboxInput
              label="Generate child command executions"
              prop="generate_child_command_executions"
              value="generate_child_command_executions_value"
              checked={newCommand.generate_child_command_executions ?? false}
              onCheckboxClick={onInputChange}
            />
          </Grid>

          <Grid item xs={12} lg={6} className="mt-6">
            <SelectInput
              label="Translator (optional)"
              prop="translator_id"
              value={newCommand.translator_id}
              onSelectChange={onInputChange}
              options={[
                <MenuItem
                  dense
                  className="m-4 p-2"
                  key="no-translator"
                  value=""
                >
                  None
                </MenuItem>,
                ...translatorsOptionsList.map((translator) => (
                  <MenuItem
                    className="m-4 p-2"
                    key={translator._id}
                    dense
                    value={translator._id}
                  >
                    {translator.name}
                  </MenuItem>
                )),
              ]}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} lg={6} className="mt-6">
            <SelectInput
              label="Workflow"
              prop="workflow_id"
              required={true}
              value={(newCommand.sender?.workflow_id as string) ?? ''}
              onSelectChange={onInputChange}
              disabled={disabled}
              options={workflowsOptionsList.map((workflow, index) => (
                <MenuItem
                  className="m-4 p-2"
                  key={index}
                  dense
                  value={workflow._id}
                >
                  {workflow.name}
                </MenuItem>
              ))}
            />
          </Grid>
          <Grid item xs={12} lg={6} className="mt-6">
            <TextInput
              label="Unique Identifier (optional)"
              prop="unique_identifier"
              required={false}
              value={(newCommand.sender?.unique_identifier as string) ?? ''}
              onInputChange={onInputChange}
              disabled={disabled}
            />
          </Grid>
        </>
      )}

      {/* Row 3 */}
      <Grid item xs={12} className="mt-6">
        <TextInput
          label="Description"
          prop="long_description"
          value={newCommand.long_description}
          onInputChange={onInputChange}
          classes="full-height"
          multiline
          rows={4}
          disabled={disabled}
        />
      </Grid>

      {/* Row 4 */}
      <Grid item xs={12} className="mt-6">
        <CodeEditor
          label="Payload"
          prop="payload"
          mode="json"
          onInputChange={onInputChange}
          value={newCommand.payload}
          width={anchorRef.current?.clientWidth}
          height={250}
        />
      </Grid>
      {/* Row 5 */}
      <Grid item xs={12} className="mt-6">
        {newCommand.sender_type === 'gcp_pubsub_sender' ? (
          <SenderCommandForm
            newCommand={newCommand}
            attributes={
              (newCommand?.sender?.attributes as { [key: string]: string }) ||
              {}
            }
            onAddRow={onAddRow}
            onRemoveRow={onRemoveRow}
            onInputChange={onDynamicChange}
          />
        ) : (
          <CodeEditor
            label="Sender"
            prop="sender"
            mode="json"
            onInputChange={onLocalHandlerChange}
            value={sender}
            width={anchorRef.current?.clientWidth}
            height={250}
          />
        )}
      </Grid>
      <Grid item xs={12} className="mt-6">
        {newCommand.sender_type === 'gcp_pubsub_sender' ? (
          <OptionsForm
            prop="options"
            objectRowsTypes={formattedJsonOption(newCommand)}
            onInputChange={onDynamicChange}
            onAddRow={onAddRow}
            onRemoveRow={onRemoveRow}
          />
        ) : (
          <CodeEditor
            label="Options"
            prop="options"
            mode="json"
            onInputChange={onLocalHandlerChange}
            value={optionsCustomJson}
            width={anchorRef.current?.clientWidth}
            height={250}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CommandForm;
