import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Person from '@mui/icons-material/Person';
import {
  AppBar,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Link,
  Collapse,
  Button,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { logoutUser } from '../../redux/reducers/user.reducer';
import { RootState } from '../../redux/store';
import ToastAlert from '../../containers/ToastAlert';
import logo from '../../assets/logo-white.png';
import links, { LinkType } from './links';
import useStyles from './styles';

interface DrawerProps {
  component: React.FC;
  path: string;
}
const ResponsiveDrawer: React.FC<DrawerProps> = ({
  component: Component,
  path,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const stateUser = useAppSelector((state: RootState) => state.user);
  const anchorRef = useRef<HTMLDivElement>(null);
  const classes = useStyles({ branding: stateUser.userCompany?.branding });

  const [mobileOpen, setMobileOpen] = useState(false);
  const [openMenuItem, setOpenMenuItem] = useState('');
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const [menuExpanded, setMenuExpanded] = useState(true);
  const [menuHovered, setMenuHovered] = useState(false);
  const logoURL = stateUser?.userCompany?.branding?.logo_url;
  const userCompany = stateUser?.userCompany;

  const menuLeftLinks: LinkType[] = [
    ...links,
    {
      highlightLinks: ['profile'],
      icon: <Person />,
      link: `profile/${stateUser.user?._id}`,
      portal_configuration: 'overview_hidden',
      text: 'User Profile',
    },
  ];

  useEffect(() => {
    menuLeftLinks.forEach((link) => {
      if (
        link.highlightLinks?.some((linkText) => path.indexOf(linkText) !== -1)
      ) {
        if (
          link.sublinks &&
          link.sublinks.length !== 0 &&
          openMenuItem !== link.text
        ) {
          setOpenMenuItem(link.text);
        }
      }
    });
  }, []);

  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen);
  };

  const handleClickUserMenu = (): void => {
    setOpenUserMenu(true);
  };

  const handleCloseUserMenu = (): void => {
    setOpenUserMenu(false);
  };

  const goToPage = (link?: string) => (): void => {
    const nextRoute = `/${link}`;
    if (link) {
      setTimeout(() => {
        navigate(nextRoute);
      }, 0);
      if (location.pathname === nextRoute) {
        navigate('/refresh');
      }
      setMobileOpen(false);
    }
  };

  const linkAction = (link: LinkType) => (): void => {
    setOpenMenuItem(link.text);

    if (link.link) {
      goToPage(link.link)();
    }
  };

  const highLightItem = (link: LinkType): boolean => {
    const pathParts = path.split('/');
    if (link.highlightLinks?.some((linkText) => pathParts[1] === linkText)) {
      return true;
    }

    return false;
  };

  const handleLogout = (): void => {
    dispatch(logoutUser());
  };

  const handleDrawerExpanded = (): void => {
    setMenuExpanded(!menuExpanded);
    if (!menuExpanded) {
      setMenuHovered(false);
    }
  };

  const handleExpandMenuOnHover = (): void => {
    if (!menuExpanded) {
      setMenuHovered(true);
    }
  };

  const handleCollapseMenuOnHover = (): void => {
    if (!menuExpanded) {
      setMenuHovered(false);
    }
  };

  const renderListItem = (
    link: LinkType,
    isSelected: boolean,
    index: number,
    type: string,
  ): JSX.Element => {
    if (
      link.ui_configuration &&
      userCompany &&
      userCompany.ui_configuration.navigation_visibility[link.ui_configuration]
    ) {
      return <></>;
    }
    return (
      <ListItem
        data-cy={`menu-${type}-${index}-${link.text}`}
        button
        component={React.forwardRef((props, _ref) => (
          <Link
            color="textPrimary"
            {...props}
            onClick={linkAction(link)}
            underline="none"
          >
            {props.children}
          </Link>
        ))}
        selected={isSelected}
        className={clsx({
          [classes.linkWithSublinks]: link.sublinks,
        })}
      >
        <ListItemIcon>{link.icon}</ListItemIcon>
        <ListItemText primary={link.text} />
      </ListItem>
    );
  };

  const renderUserName = (noWrap = true): JSX.Element => {
    return (
      <>
        <div className={clsx('mr-2 user-avatar', classes.userMenuAvatar)}>
          <Typography noWrap variant="h5" className="user-item-text">
            {stateUser.user?.first_name.charAt(0)}
            {stateUser.user?.last_name.charAt(0)}
          </Typography>
        </div>
        <div
          className={clsx({
            [classes.userMenuName]: noWrap,
          })}
        >
          <Typography
            variant="subtitle1"
            className="user-item-text"
            noWrap={noWrap}
          >
            {stateUser.userCompany?.name}
          </Typography>
          <Typography
            variant="overline"
            component="div"
            className="user-item-text"
            noWrap={noWrap}
          >
            {stateUser.user?.email}
          </Typography>
        </div>
      </>
    );
  };

  const drawer = (type: string): JSX.Element => (
    <div className={clsx('scrollbar', classes.drawerInnerContainer)}>
      <div className={clsx('ml-8', classes.logoContainer)}>
        <img
          src={logoURL ? logoURL : logo}
          className={clsx('mt-9 mb-7', classes.companyLogo)}
          alt="company-logo"
        />
      </div>
      <List className={classes.drawerList} component="div">
        <div>
          {menuLeftLinks.map((link: LinkType, index: number) => {
            const isLinkSelected = highLightItem(link);
            return (
              <div key={`${link.text}-${index}`}>
                {link.sublinks ? (
                  <>
                    {renderListItem(link, isLinkSelected, index, type)}
                    <Collapse
                      in={openMenuItem === link.text}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List
                        component="div"
                        disablePadding
                        className={classes.nestedList}
                      >
                        {link.sublinks.map((sublink: LinkType, i: number) => {
                          if (
                            sublink.readModel &&
                            stateUser?.permissions &&
                            stateUser?.permissions[sublink.readModel]
                          ) {
                            const showItem =
                              stateUser.permissions[sublink.readModel].read;
                            if (!showItem) {
                              return (
                                <React.Fragment
                                  key={`${sublink.text}-${i}`}
                                ></React.Fragment>
                              );
                            }
                          }

                          if (
                            sublink.ui_configuration &&
                            userCompany &&
                            userCompany.ui_configuration.navigation_visibility[
                              sublink.ui_configuration
                            ]
                          ) {
                            return (
                              <React.Fragment
                                key={`${sublink.text}-${i}`}
                              ></React.Fragment>
                            );
                          }

                          const isSelected = highLightItem(sublink);
                          return (
                            <ListItem
                              data-cy={`menu-${type}-${index}-${i}-${sublink.text}`}
                              key={`${sublink.text}-${i}`}
                              button
                              component={React.forwardRef((props, _ref) => (
                                <Link
                                  data-cy={`menu-${i}-${sublink.text.replace(
                                    /\s/g,
                                    '',
                                  )}`}
                                  color="primary"
                                  {...props}
                                  onClick={goToPage(sublink.link)}
                                  underline="none"
                                >
                                  {props.children}
                                </Link>
                              ))}
                              selected={isSelected}
                            >
                              <ListItemText primary={sublink.text} />
                            </ListItem>
                          );
                        })}
                      </List>
                    </Collapse>
                  </>
                ) : (
                  renderListItem(link, isLinkSelected, index, type)
                )}
              </div>
            );
          })}
        </div>
        <div ref={anchorRef} className="px-6">
          <ListItem
            button
            component={React.forwardRef((props, _ref) => (
              <Link
                {...props}
                underline="none"
                className={clsx('p-4', classes.userMenuButton, {
                  [classes.userMenuButtonFocused]: openUserMenu,
                  'br-2': openUserMenu,
                })}
                onClick={handleClickUserMenu}
              >
                {renderUserName()}
                {props.children}
                {!openUserMenu ? (
                  <KeyboardArrowDown className={classes.userItemText} />
                ) : (
                  <KeyboardArrowUp className={classes.userItemText} />
                )}
              </Link>
            ))}
          ></ListItem>
          <Menu
            anchorEl={anchorRef.current}
            open={openUserMenu}
            onClose={handleCloseUserMenu}
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'top',
            }}
            transformOrigin={{
              horizontal: 'left',
              vertical: 'bottom',
            }}
            className={classes.userMenu}
          >
            <MenuItem
              onClick={handleCloseUserMenu}
              disableRipple
              className={classes.userMenuItem}
            >
              {renderUserName(false)}
            </MenuItem>
            <MenuItem onClick={handleCloseUserMenu} disableRipple>
              <Button
                variant="text"
                disableElevation
                onClick={handleLogout}
                className={classes.userMenuItem}
              >
                Sign out
              </Button>
            </MenuItem>
          </Menu>
          <Typography className={classes.appVersion} variant="overline">
            v{process.env.REACT_APP_VERSION}
          </Typography>
        </div>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <div className={classes.desktopHidden}>
        <AppBar position="fixed" color="inherit" elevation={2}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            {/* <Typography variant="h2" color="textPrimary" gutterBottom={false}>
              {getPageTitle(path)}
            </Typography> */}
          </Toolbar>
        </AppBar>
      </div>
      <div
        className={clsx(classes.expandButtonContainer, {
          ['collapsed']: !menuExpanded,
          ['hovered']: menuHovered,
        })}
      >
        <Tooltip title={menuExpanded ? 'Collapse' : 'Expand'} placement="right">
          <IconButton
            color="inherit"
            aria-label="expand drawer"
            edge="start"
            onClick={handleDrawerExpanded}
            className={classes.expandButton}
            onMouseEnter={handleExpandMenuOnHover}
          >
            {menuExpanded ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </Tooltip>
      </div>
      <nav
        className={clsx(classes.drawer, {
          ['collapsed']: !menuExpanded,
          ['expanded']: menuExpanded,
          ['hovered']: menuHovered,
        })}
        aria-label="mailbox folders"
        onMouseEnter={handleExpandMenuOnHover}
        onMouseLeave={handleCollapseMenuOnHover}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <div className={classes.desktopHidden}>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaperMobile,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer('mobile')}
          </Drawer>
        </div>
        <div className={classes.mobileHidden}>
          <Drawer
            className={clsx('scrollbar', classes.drawerPaper)}
            variant="permanent"
            open
          >
            {drawer('desktop')}
          </Drawer>
        </div>
      </nav>
      <main className={clsx('scrollbar', classes.content)}>
        <ToastAlert />
        <Component></Component>
      </main>
    </div>
  );
};

export default ResponsiveDrawer;
