import { Company, User, UserType } from '@edgeiq/edgeiq-api-js';
import { Box, Typography } from '@mui/material';
import AccountIcon from '@mui/icons-material/AccountCircle';
import clsx from 'clsx';

import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import useStyles from './styles';
import { titleCase } from '../../helpers/utils';

interface UserCardProps {
  user: User;
  userType?: UserType;
  userCompanies?: Company[];
}

const UserCard: React.FC<UserCardProps> = ({
  user,
  userType,
  userCompanies,
}) => {
  const { user: loggedUser } = useAppSelector((state: RootState) => state.user);
  const { first_name, last_name, email } = user;
  const classes = useStyles();
  const avatarText = first_name.charAt(0) + last_name.charAt(0);

  const company = userCompanies?.find(
    (userCompany) => userCompany._id === user.company_id,
  );

  return (
    <Box className={clsx(classes.cardContainer)}>
      <Typography
        variant="h1"
        component="div"
        noWrap
        className={clsx('loading-container', classes.avatarName)}
      >
        {avatarText}
      </Typography>
      <Typography
        component="div"
        variant="h5"
        className={clsx('mt-4', classes.name)}
      >
        {first_name} {last_name} {user._id === loggedUser?._id ? '(Me)' : ''}
      </Typography>
      <Typography component="div" variant="h6" className={clsx(classes.email)}>
        {email}
      </Typography>
      {userType && (
        <Typography
          component="div"
          variant="caption"
          className={clsx('px-2 py-1 br-1 mt-4', classes.role)}
        >
          {titleCase(userType.name)}
        </Typography>
      )}
      {company && (
        <Typography
          component="div"
          variant="overline"
          className={clsx('mt-4', classes.account)}
        >
          <AccountIcon className={clsx('mr-2')} />
          {company.name}
        </Typography>
      )}
    </Box>
  );
};

export default UserCard;
