export const PAGE_OPTIONS_LABEL: {
  [key: string]: string;
} = {
  configuration_hidden: 'Configuration',
  data_management_hidden: 'Data Management',
  devices_hidden: 'Devices',
  integrations_hidden: 'Integrations',
  messages_hidden: 'Messages',
  scheduled_jobs_hidden: 'Scheduled Jobs',
  software_hidden: 'Software',
  workflows_hidden: 'Workflows',
};
