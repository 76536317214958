import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Command } from '@edgeiq/edgeiq-api-js';

import RightDrawer from '../../../components/RightDrawer/RightDrawer';
import TextInput from '../../../components/TextInput';

interface ExecuteCommandDrawerProps {
  open: boolean;
  command?: Command;
  handleCloseDrawer: () => void;
  onExecuteCommand: () => void;
  onOptionChange: (prop: string, value: string | number) => void;
}

const ExecuteCommandDrawer: React.FC<ExecuteCommandDrawerProps> = ({
  open,
  command,
  handleCloseDrawer,
  onExecuteCommand,
  onOptionChange,
}) => {
  return (
    <RightDrawer
      open={open}
      actionLabel="Execute"
      title="Execute Command"
      disableAction={false}
      actionCallback={onExecuteCommand}
      onCloseDrawer={handleCloseDrawer}
      content={
        command ? (
          <Box>
            <Typography variant="subtitle1">
              Choose options to execute command: {command.name}
            </Typography>

            {command.options &&
              Object.keys(command.options).map((key, index) => (
                <Grid
                  item
                  xs={12}
                  className="d-flex flex-items-center mt-8"
                  key={index}
                >
                  <Typography variant="h6" className="mr-2">
                    {key}
                  </Typography>
                  <TextInput
                    prop={key}
                    value={
                      command.options
                        ? (
                            command.options[key] as {
                              default: string;
                            }
                          ).default
                        : ''
                    }
                    onInputChange={onOptionChange}
                  />
                </Grid>
              ))}
          </Box>
        ) : (
          <></>
        )
      }
    />
  );
};

export default ExecuteCommandDrawer;
