import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Grid, Typography } from '@mui/material';

import RightDrawer from '../../../components/RightDrawer/RightDrawer';
import {
  Command,
  CommandExecution,
  CommandExecutions,
  Device,
  Devices,
  Execution,
  PaginationFilter,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { setAlert } from '../../../redux/reducers/alert.reducer';
import {
  commandExecutionColorThemeMap,
  defaultItemsPerPage,
  errorHighlight,
} from '../../../app/constants';
import { useAppDispatch } from '../../../redux/hooks';
import timeHelpers from '../../../helpers/timeHelpers';
import { useUserWithType } from '../../../hooks/useUserWithType';
import UserAvatar from '../../../components/UserAvatar';
import ColoredBox from '../../../components/ColoredBox';
import useCommandStyles from '../../../pages/commandContent/commandExecutionList/styles';
import useStyles from '../BulkJobInformationDrawer/styles';

interface CommandExecutionInformationDrawerProps {
  open: boolean;
  handleCloseDrawer: () => void;
  selectedExecution: CommandExecution | undefined;
  commands: Command[];
}

const CommandExecutionInformationDrawer: React.FC<
  CommandExecutionInformationDrawerProps
> = ({ open, handleCloseDrawer, selectedExecution, commands }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const commandClasses = useCommandStyles();
  const [device, setDevice] = useState<undefined | Device>();
  const { user } = useUserWithType(selectedExecution?.user_id ?? '', false);
  const [statuses, setStatuses] = useState<Execution[]>([]);
  const [page, setPage] = useState(1);
  const [_total, setTotal] = useState(0);
  const [_loading, setLoading] = useState(true);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const getCommandName = (commandId: string): string => {
    const foundCommand = commands.find((command) => command._id === commandId);
    if (foundCommand) {
      return foundCommand.name;
    }
    return '--';
  };

  const getDevice = (): void => {
    if (selectedExecution) {
      Devices.getOneById(selectedExecution.device_id)
        .then((res) => {
          if (res) {
            setDevice(res);
          }
        })
        .catch((error) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: error.message,
              type: 'error',
            }),
          );
        });
    }
  };

  const getStatuses = (pageNumber: number, addPage = false): void => {
    if (selectedExecution) {
      const pagination: PaginationFilter = {
        itemsPerPage: defaultItemsPerPage,
        order_by: 'status',
        page: pageNumber,
      };
      CommandExecutions.listCommandExecutionStatuses(
        selectedExecution._id,
        {},
        pagination,
      )
        .then((result) => {
          console.info(result);
          const results: Execution[] = addPage
            ? [...statuses, ...result.statuses]
            : result.statuses;
          setStatuses(results);
          setTotal(result.pagination.total);
          if (addPage) {
            setPage(page + 1);
          }
        })
        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getDevice();
    getStatuses(1);
  }, [selectedExecution]);

  const goToCommand = (): void => {
    navigate(`/command/${selectedExecution?.command_id}`);
  };

  const goToDevice = (): void => {
    navigate(`/device/${selectedExecution?.device_id}`);
  };

  const _handleLoadMore = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();
    setLoading(true);
    getStatuses(page + 1, true);
  };

  if (!selectedExecution) {
    return <></>;
  }
  return (
    <RightDrawer
      open={open}
      actionLabel="Done"
      title="Command Execution Information"
      disableAction={false}
      actionCallback={handleCloseDrawer}
      onCloseDrawer={handleCloseDrawer}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className="custom-label">
              Created At
            </Typography>
            <Typography variant="subtitle2" className="custom-label">
              {timeHelpers.getDate(selectedExecution.created_at)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className="custom-label">
              Created By
            </Typography>
            <UserAvatar
              name={user ? `${user?.first_name} ${user?.last_name}` : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className="custom-label">
              Command
            </Typography>
            <Typography
              variant="subtitle2"
              className={clsx('custom-label', classes.linkButton)}
              onClick={goToCommand}
            >
              {getCommandName(selectedExecution.command_id)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className="custom-label">
              Device
            </Typography>
            <Typography
              variant="subtitle2"
              className={clsx('custom-label', classes.linkButton)}
              onClick={goToDevice}
            >
              {device?.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className="custom-label">
              Status
            </Typography>
            {statuses &&
              statuses.map((item) => (
                <div key={item._id} className="w-100">
                  <Divider className="my-2" />
                  <Grid
                    item
                    xs={12}
                    className={clsx(
                      'scrollbar',
                      commandClasses.executionInfoContainer,
                    )}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={10}>
                        <Typography variant="caption" color="white">
                          {timeHelpers.getDate(item.created_at, false, true)}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <ColoredBox
                          colorTheme={
                            commandExecutionColorThemeMap[item.status]
                          }
                          type="command_execution_state"
                          value={item.status}
                        />
                      </Grid>
                      {item.status !== 'initiated' && (
                        <Grid item xs={12}>
                          <pre className={commandClasses.executionInfo}>
                            {item?.message}
                          </pre>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </div>
              ))}
          </Grid>
        </Grid>
      }
    />
  );
};

export default CommandExecutionInformationDrawer;
