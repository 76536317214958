import {
  IngestorHandler,
  IngestorInput,
  IngestorListener,
  ListenerType,
} from '@edgeiq/edgeiq-api-js';
import { methodsTypes } from '../app/constants';
import { getDefaultHandlerTypes } from '../helpers/ingestors';

/**
 * DEFAULT LISTENERS BY TYPE
 */
export const ICMP_POLLING_LISTENER: IngestorListener = {
  host: '',
  poll_interval: 60,
  timeout: 10,
};

export const BACNET_LISTENER: IngestorListener = {
  ...ICMP_POLLING_LISTENER,
  params: {
    object_instance: 0,
    object_type: 'analog_input',
    property_id: '',
    value: '',
  },
  port: 0,
};

export const DBUS_SIGNAL_LISTENER: IngestorListener = {
  filters: [
    {
      interface: '',
      member: '',
      path: '',
    },
  ],
};

export const DEV_LISTENER: IngestorListener = {
  baud: '1200',
  dev: '',
};

export const HTTP_CLIENT_LISTENER: IngestorListener = {
  method: '',
  poll_interval: 60,
  timeout: 10,
  url: '',
};

export const HTTP_SERVER_LISTENER: IngestorListener = {
  port: 0,
};

export const OPCUA_LISTENER: IngestorListener = {
  ...ICMP_POLLING_LISTENER,
  params: {
    node_id: '',
    request_type: 'read_single_int32',
    value: '',
  },
  port: 0,
};

export const SHELL_POLLING_LISTENER: IngestorListener = {
  command: '',
  poll_interval: 60,
  timeout: 10,
};

export const SNMP_POLLING_LISTENER: IngestorListener = {
  ...ICMP_POLLING_LISTENER,
  community: '',
  oids: [],
  retries: 0,
  version: '1',
};
/*********/

/**
 * DEFAULT HANDLERS BY TYPE
 */
// This one is used for: bacnet, dev, icmp_polling
export const FIXED_HANDLER: IngestorHandler = {
  length: 0,
};

export const DBUS_SIGNAL_HANDLER: IngestorHandler = {
  index: 0,
  type: 'int32',
};

// This one is used for: http_client
export const ROUTER_HANDLER: IngestorHandler = {
  routes: [
    {
      handler_type: 'router',
      methods: [methodsTypes.get],
      path: '',
    },
  ],
};
/*********/

export const EMPTY_INGESTOR: IngestorInput = {
  company_id: '',
  handler: {},
  handler_type: 'passthrough',
  listener: HTTP_SERVER_LISTENER,
  listener_type: 'http_server',
  name: '',
  translator_id: '',
  type: 'edge',
};

export const INGESTOR_LISTENER_TYPES: {
  [key: string]: ListenerType;
} = {
  bacnet: 'bacnet',
  cloud: 'cloud',
  cloud_gcp_pubsub_jci: 'cloud_gcp_pubsub_jci',
  cloud_polling_assetlink: 'cloud_polling_assetlink',
  cloud_polling_inmarsat: 'cloud_polling_inmarsat',
  cloud_polling_orbcomm: 'cloud_polling_orbcomm',
  dbus_signal: 'dbus_signal',
  dev: 'dev',
  http_client: 'http_client',
  http_server: 'http_server',
  icmp_polling: 'icmp_polling',
  opcua: 'opcua',
  shell_polling: 'shell_polling',
  snmp_polling: 'snmp_polling',
  tcp_client: 'tcp_client',
  tcp_modbus: 'tcp_modbus',
  tcp_server: 'tcp_server',
  udp_client: 'udp_client',
  udp_server: 'udp_server',
};

export const INGESTOR_LISTENER_TYPES_MAP: {
  [key: string]: string;
} = {
  bacnet: 'BACnet',
  cloud: 'Cloud HTTP Ingest',
  cloud_gcp_pubsub_jci: 'GCP Pub/Sub',
  cloud_polling_assetlink: 'Assetlink Cloud Polling',
  cloud_polling_inmarsat: 'Inmarsat Cloud Polling',
  cloud_polling_orbcomm: 'ORBCOMM Cloud Polling',
  dbus_signal: 'D-Bus Signal',
  dev: 'Linux Dev',
  http_client: 'HTTP Client',
  http_server: 'HTTP Server',
  icmp_polling: 'ICMP Polling',
  opcua: 'OPC UA',
  shell_polling: 'Shell Polling',
  snmp_polling: 'SNMP Polling',
  tcp_client: 'TCP Client',
  tcp_modbus: 'TCP Modbus',
  tcp_server: 'TCP Server',
  udp_client: 'UDP Client',
  udp_server: 'UDP Server',
};

export const INGESTOR_HANDLER_TYPES_MAP: {
  [key: string]: string;
} = {
  dbus: 'D-BUS',
  delimited: 'Delimited',
  fixed: 'Fixed',
  passthrough: 'Passthrough',
  router: 'Router',
};

export const INGESTOR_TYPES_MAP: {
  [key: string]: string;
} = {
  cloud: 'Cloud',
  edge: 'Edge',
};

export const DEFAULT_HANDLER_TYPES_MAP = getDefaultHandlerTypes(
  INGESTOR_LISTENER_TYPES_MAP,
);
