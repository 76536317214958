import React, { ReactElement, useEffect, useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import useStyles from './styles';

export interface SideTabsProps {
  tabs: {
    [key: string]: JSX.Element;
  };
  defaultTab: string;
  tabsLabel: {
    [key: string]: string;
  };
  padding?: boolean;
  changeUrl?: boolean;
  contentFullHeight?: boolean;
  handleTabChange?: (tab: string) => void;
}

const VerticalTabs: React.FC<SideTabsProps> = ({
  tabs,
  defaultTab,
  tabsLabel,
  padding = true,
  changeUrl = false,
  contentFullHeight,
  handleTabChange,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [chosenTab, setChosenTab] = useState<string>(defaultTab);

  useEffect(() => {
    setChosenTab(defaultTab);
  }, [defaultTab]);

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: string,
  ): void => {
    setChosenTab(newValue);
    if (handleTabChange) {
      handleTabChange(newValue);
    }
    if (changeUrl) {
      navigate(`${location.pathname}#${newValue}`, {
        state: { ...location.state },
      });
    }
  };

  return (
    <>
      <Box
        data-testid="tabs-container"
        className={clsx('scrollbar', classes.container, {
          ['px-8']: padding,
        })}
      >
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={chosenTab}
          onChange={handleChange}
        >
          {Object.keys(tabs).map(
            (label, index): ReactElement => (
              <Tab
                key={`tab-${label}-${index}`}
                data-cy={`tab-${label}`}
                label={tabsLabel[label]}
                value={label}
              />
            ),
          )}
        </Tabs>
      </Box>
      <Box
        data-testid="tab-view-container"
        className={clsx({
          ['px-8']: padding,
          ['py-6']: padding,
          [classes.contentFullHeight]: contentFullHeight,
        })}
      >
        {tabs[chosenTab]}
      </Box>
    </>
  );
};

export default VerticalTabs;
