import React, { ChangeEvent } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { Company, CompanyInput } from '@edgeiq/edgeiq-api-js';

import TextInput from '../../../../components/TextInput';
import ColorPickerInput from '../../../../components/ColorPicker';
import DropZone from '../../../../components/DropZone';

interface AccountFormProps {
  company?: Company;
  companyInput?: CompanyInput;
  uploadedLogo?: File;
  onInputChange: (prop: string, value: string | number) => void;
  onCheckboxChange: (prop: string, value: boolean) => void;
  setUploadedLogo: React.Dispatch<React.SetStateAction<File | undefined>>;
}

const validateInputValues = (
  company?: string,
  companyInput?: string,
): string => {
  if (company) {
    return company;
  }
  if (companyInput) {
    return companyInput;
  }
  return '';
};

const AccountForm: React.FC<AccountFormProps> = ({
  company,
  companyInput,
  uploadedLogo,
  onCheckboxChange,
  onInputChange,
  setUploadedLogo,
}) => {
  const handleCheckboxChange =
    (prop: string) =>
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      onCheckboxChange(prop, checked);
    };

  const _handleRadioChange =
    (prop: string) =>
    (_event: ChangeEvent<HTMLInputElement>, value: string): void => {
      onInputChange(prop, value);
    };

  // if company._id is defined it means we are entering the form to edit the account
  const branding = company?._id ? company.branding : companyInput?.branding;

  const onAddFile = (file: File): void => {
    setUploadedLogo(file);
  };

  const onRemoveFile = (): void => {
    setUploadedLogo(undefined);
  };

  return (
    <Box>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={6} className="mt-3">
          <TextInput
            label="Name"
            placeholder="Account Name"
            prop="name"
            required={true}
            onInputChange={onInputChange}
            value={validateInputValues(company?.name, companyInput?.name)}
          />
        </Grid>
        <Grid item xs={12} md={6} className="mt-3">
          <TextInput
            label="X.509 Certificate Domain"
            onInputChange={onInputChange}
            placeholder={
              company?.custom_certificate_domain
                ? company.custom_certificate_domain
                : company?._id
                ? `${company._id}.devices.edgeiq.ai`
                : 'devices.edgeiq.ai'
            }
            prop="name"
            required={false}
            disabled={true}
            value={
              company?.custom_certificate_domain
                ? company.custom_certificate_domain
                : company?._id
                ? `${company._id}.devices.edgeiq.ai`
                : 'devices.edgeiq.ai'
            }
          />
        </Grid>

        {!company?._id && (
          <Grid item xs={12} className="mt-3 ">
            <TextInput
              label="Account ID"
              placeholder="Account ID"
              prop="_id"
              required={true}
              value={companyInput?._id ?? ''}
              onInputChange={onInputChange}
            />
          </Grid>
        )}

        <Grid item xs={12} className="mt-3">
          <TextInput
            label="Logo URL"
            onInputChange={onInputChange}
            placeholder="Logo URL"
            prop="branding.logo_url"
            value={branding?.logo_url}
          />
        </Grid>

        <Grid item xs={12} className="mt-3">
          <TextInput
            label="Icon URL"
            onInputChange={onInputChange}
            placeholder="Icon URL"
            prop="branding.icon_url"
            value={branding?.icon_url}
          />
        </Grid>

        <Grid item xs={12} className="mt-3">
          <TextInput
            label="Portal Title"
            onInputChange={onInputChange}
            placeholder="Portal Title"
            prop="branding.portal_title"
            value={branding?.portal_title}
          />
        </Grid>

        <Grid item xs={12} sm={12} className="mt-3">
          <Typography variant="h6" className="mb-4">
            Logo
          </Typography>
          <DropZone
            title="Rectangular shaped logo is recommended. Allowed file types: JPEG, PNG, SVG, WEBP, BMP"
            acceptTypes={[
              'image/jpeg',
              'image/png',
              'image/svg+xml',
              'image/x-ms-bmp',
              'image/webp',
            ]}
            stateFiles={uploadedLogo ? [uploadedLogo] : []}
            onFileUpload={onAddFile}
            onRemoveFile={onRemoveFile}
          />
        </Grid>

        <Grid item xs={12} sm={6} className="mt-3">
          <ColorPickerInput
            disabled={true}
            label="Buttons Background Color"
            labelInfoTooltip="The color used for all the buttons and links besides the side menu."
            onInputChange={onInputChange}
            placeholder="Buttons Background Color"
            prop="branding.button_background_color"
            value={branding?.button_background_color}
          />
        </Grid>

        <Grid item xs={12} sm={6} className="mt-3">
          <ColorPickerInput
            disabled={true}
            label="Buttons Text Color"
            labelInfoTooltip={`The color used for the text in the filled buttons. Tip: Use a color that contrast well with "Buttons Background Color"`}
            onInputChange={onInputChange}
            placeholder="Buttons Text Color"
            prop="branding.button_text_color"
            value={branding?.button_text_color}
          />
        </Grid>

        <Grid item xs={12} sm={6} className="mt-3">
          <ColorPickerInput
            disabled={true}
            label="Sidebar Primary Color"
            labelInfoTooltip="The main color of the sidebar menu, scroll bars, and other items."
            onInputChange={onInputChange}
            placeholder="Sidebar Primary Color"
            prop="branding.primary_color"
            value={branding?.primary_color}
          />
        </Grid>

        <Grid item xs={12} sm={6} className="mt-3">
          <ColorPickerInput
            disabled={true}
            label="Sidebar Links Color"
            labelInfoTooltip={`The color of the sidebar menu links and icons. The color of the menu items when selecting or hovering over them. Tip: Use a color that contrast well with "Sidebar Primary Color"`}
            onInputChange={onInputChange}
            placeholder="Sidebar Links Color"
            prop="branding.sidebar_text_color"
            value={branding?.sidebar_text_color}
          />
        </Grid>

        <Grid item xs={12} sm={6} className="mt-3" display="flex">
          <FormControlLabel
            control={
              <Checkbox
                data-cy="checkbox-gradient_sidbar"
                checked={branding?.gradient_sidbar}
                onChange={handleCheckboxChange('branding.gradient_sidbar')}
              />
            }
            label="Gradient Sidebar Background"
          />
        </Grid>

        {branding?.gradient_sidbar && (
          <Grid item xs={12} sm={6} className="mt-3">
            <ColorPickerInput
              data-cy="color-picker-secondary_color"
              disabled={true}
              label="Sidebar second Color"
              onInputChange={onInputChange}
              placeholder="Sidebar second Color"
              prop="branding.secondary_color"
              value={branding?.secondary_color}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AccountForm;
