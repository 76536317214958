import {
  Autorenew as AutorenewIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  NewReleases as NewReleasesIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { BulkResponse } from '@edgeiq/edgeiq-api-js';

export const getBulkResponseStatus = (
  bulkResponse: BulkResponse,
  withTooltip = true,
): React.ReactElement => {
  const status = bulkResponse.bulk_status;
  let icon: React.ReactElement = <></>;
  let text: string = '';
  if (status === 'created') {
    icon = <NewReleasesIcon color="info" />;
    text = 'Created successfully';
  } else if (status === 'started') {
    icon = <AutorenewIcon color="info" />;
    text = 'Processing';
  } else if (status === 'aborted') {
    icon = <ErrorIcon color="error" />;
    text = 'Aborted';
  } else if (status === 'finished' || !status) {
    // !status sor the cases where the status has not been set before. We use the numbers of the totals to show the main status of the bulk response
    if (bulkResponse.successes === bulkResponse.total) {
      icon = <CheckCircleIcon color="success" />;
      text = 'All items processed successfully';
    } else if (bulkResponse.failures) {
      icon = <WarningIcon color="error" />;
      text = 'Finished with failures';
    } else {
      icon = <WarningIcon color="warning" />;
      text = `Some items didn't process successfully`;
    }
  }

  if (icon && text) {
    return withTooltip ? (
      <Tooltip title={text} placement="bottom-start">
        {icon}
      </Tooltip>
    ) : (
      <div className="d-flex flex-items-center">
        {icon}
        <span style={{ marginLeft: '6px' }}>{text}</span>
      </div>
    );
  }

  return <>--</>;
};
